import {
  ADD_ACTIVITY,
  ADD_ACTIVITY_FAILURE,
  ADD_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_STATE,
  DELETE_ACTIVITY_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
} from "../actions/activities.actions";

const initialState = {
  data: null,
  loading: false,
};

const activities = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_ACTIVITY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_ACTIVITY_STATE:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default activities;
