import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LineChartComponent from "shared-components/src/components/charts/lineChart.component";
import CustomizedTables from "shared-components/src/components/displayData/CustomizedTables.component";
import FieldSelectorComponent from "../components/fieldSelector/FieldSelector.component";
import { fetchSensorsMeasureRequest } from "../store/actions/realtime.actions";
import dayjs from "dayjs";
import { fetchSensorMeasureDataRequest } from "../store/actions/realtimeSensorMeasure.actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IrrigationActionsForm from "../components/irrigationActionsForm/IrrigationActionsForm.component";
import MultitypeChartComponent from "shared-components/src/components/charts/multitypeChart.component";
import {
  chartColors,
  data,
  options,
  useTodayIntersection,
  verticalLinePlugin,
} from "shared-components/src/components/charts/utils/realtimeChartConf";
import CustomTooltip from "shared-components/src/components/tooltip/customTooltip.component";
import MapFielComponent from "../components/map/MapField.component";
import { fetchDatastreamListRequest } from "../../../acquaount-dashboard-farm/src/store/actions/realtimeDatastreamList.actions";
import WeatherStationComponent from "shared-components/src/components/weatherStation/weatherStation.component";
import NoDataPlaceholder from "shared-components/src/components/noDataPlaceholder/noDataPlaceholder.component";

function getLabels(sensorData) {
  if (Array.isArray(sensorData)) {
    const sensorDataReverse = sensorData.slice().reverse();
    return sensorDataReverse.map((data) =>
      dayjs(data.time_of_measure).format("DD/MM/YYYY HH:mm")
    );
  }
}
function getDatasets(sensorData) {
  if (Array.isArray(sensorData)) {
    const sensorDataReverse = sensorData.slice().reverse();
    const label = sensorData?.[0]?.unit_of_measurement;
    const data = sensorDataReverse.map((data) => data.value);
    const datasets = [
      {
        label: label,
        data: data,
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 2,
        backgroundColor: "rgb(75, 192, 192)",
      },
    ];
    return datasets;
  }
}

const RealtimePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 767px");
  const [datastreamsMeasure, setDatastreamsMeasure] = useState([]);

  const fieldSelected = useSelector((state) => state.field.data);
  const sensorMeasureData = useSelector(
    (state) => state.sensorMeasureData.data
  );
  const fields = useSelector((state) => state.fields.data);
  const datastreamsList = useSelector((state) => state?.datastreamList?.data);
  const lastMeasure = useSelector((state) => state?.sensorsMeasure?.data);

  const filterByDeviceID = (data, deviceID) => {
    return data?.filter((item) => item.deviceID === deviceID);
  };

  const field = fields.find((f) => f.id === fieldSelected);
  const sensors_data = field?.sensors;
  const [selectedSensor, setSelectedSensorValue] = useState("");
  const filteredLastMeasure = filterByDeviceID(lastMeasure, selectedSensor);

  useEffect(() => {
    const filteredDatastreams = Array.isArray(datastreamsList)
      ? datastreamsList.filter((item) => item.deviceID === selectedSensor)
      : [];
    setDatastreamsMeasure(filteredDatastreams);
  }, [datastreamsList, selectedSensor]);

  const [selectedMeasure, setSelectedMeasureValue] = useState("");
  const { fullIrrigation, deficitIrrigation } = useTodayIntersection();
  const start_time = new Date();
  start_time.setDate(start_time.getDate() - 10);
  const end_time = new Date();
  const [startDate, setStartDateValue] = useState(start_time);
  const [endDate, setEndDateValue] = useState(end_time);
  const sensorChartData = Array.isArray(sensorMeasureData)
    ? {
        labels: getLabels(sensorMeasureData),
        datasets: getDatasets(sensorMeasureData),
      }
    : null;

  const unit = sensorMeasureData?.[0]?.unit_of_measurement || "Unità";

  useEffect(() => {
    if (field) {
      dispatch(fetchSensorsMeasureRequest(field.id_thing));
      dispatch(fetchDatastreamListRequest(field.id_thing));
      setSelectedSensorValue(undefined);
      setSelectedMeasureValue(undefined);

      const intervalId = setInterval(() => {
        dispatch(fetchSensorsMeasureRequest(field.id_thing));
      }, 300000);
      return () => clearInterval(intervalId);
      setSelectedSensorValue("");
      setSelectedMeasureValue("");
    }
  }, [field, dispatch]);

  const handleSensorChange = (event) => {
    const sensorID = event.target.value;
    setSelectedSensorValue(sensorID);
    setSelectedMeasureValue("");
  };

  const handleMeasureChange = (event) => {
    const datastream_name = event.target.value;
    setSelectedMeasureValue(datastream_name);
    measureDataRequest(datastream_name, startDate, endDate);
  };

  const measureDataRequest = useCallback(
    (datastream_name, start_time, end_time) => {
      if (field) {
        dispatch(
          fetchSensorMeasureDataRequest({
            field: field.id_thing,
            datastream_name: datastream_name,
            start_time: start_time.toISOString(),
            end_time: end_time.toISOString(),
          })
        );
      }
    },
    [field, dispatch]
  );

  const handleStartDateChange = (newStartDate) => {
    const maxEndDate = dayjs(newStartDate).add(10, "day");
    if (maxEndDate.isBefore(endDate)) {
      setEndDateValue(maxEndDate.toDate());
    }
    setStartDateValue(newStartDate);
  };

  const handleEndDateChange = (newEndDate) => {
    const minStartDate = dayjs(newEndDate).subtract(10, "day");
    if (minStartDate.isAfter(startDate)) {
      setStartDateValue(minStartDate.toDate());
    }
    setEndDateValue(newEndDate);
  };

  useEffect(() => {
    measureDataRequest(selectedMeasure, startDate, endDate);
  }, [startDate, endDate, measureDataRequest, selectedMeasure]);

  const readDate = (date) => {
    const value = dayjs(new Date(date));
    return value;
  };

  const extractUnitSymbol = (unit) => {
    const match = unit.match(/\((.*?)\)/);
    return match ? match[1] : unit;
  };

  return (
    <Fragment>
      <h3>{t("real-time-page.title")}</h3>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        {/* Subtitle */}
        {!isMobile && (
          <Grid item xs={12}>
            <span>{t("real-time-page.subtitle")}</span>
          </Grid>
        )}
        {/* Field Selector */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FieldSelectorComponent />
            </div>
          </Paper>
        </Grid>
        {/* Chart and map */}
        {fieldSelected && (
          <>
            <Grid item xs={12} md={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Paper
                    variant="outlined"
                    sx={{ height: "100%", padding: "20px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <h4>{t("real-time-page.irrigation-profile")}</h4>
                      <CustomTooltip title={t("real-time-page.tooltip_info")} />
                    </Box>
                    <MultitypeChartComponent
                      options={options}
                      data={data}
                      plugins={[verticalLinePlugin]}
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      marginTop={1}
                      justifyContent="center"
                    >
                      <Chip
                        label={`${t(
                          "real-time-page.suggested_irrigation"
                        )}:  ${fullIrrigation} m^3`}
                        sx={{
                          backgroundColor: chartColors.LIGHT_FULL_IRRIGATION,
                          color: chartColors.FULL_IRRIGATION,
                        }}
                        size="medium"
                      />
                      <Chip
                        label={`${t(
                          "real-time-page.suggested_deficit_irrigation"
                        )}:  ${deficitIrrigation} m^3`}
                        sx={{
                          backgroundColor: chartColors.LIGHT_DEFICIT_IRRIGATION,
                          color: chartColors.DEFICIT_IRRIGATION,
                        }}
                        size="medium"
                      />
                    </Stack>
                    <IrrigationActionsForm fullIrrigation={fullIrrigation} />
                  </Paper>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid lightgray",
                  width: "100%",
                  height: "100%",
                  p: 2,
                  marginBottom: 2,
                  bgcolor: "white",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <MapFielComponent isRealtime />
              </Box>
            </Grid>
            {/* sensor and weather station */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginY: "20px",
                marginLeft: "15px",
                gap: 2,
                flexDirection: isMobile ? "column" : "row",
                bgcolor: "white",
                padding: "20px",
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: 2,
                  flexDirection: "column",
                  bgcolor: "#f5f5f5",
                  padding: "10px",
                  borderRadius: "5px",
                  p: 2,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h4>Time range</h4>
                  <Stack spacing={2} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("real-time-page.date_picker.start")}
                        name="startDate"
                        value={readDate(startDate)}
                        format="DD/MM/YYYY"
                        onChange={(date) => handleStartDateChange(date)}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                        maxDate={readDate(endDate)}
                        sx={{ bgcolor: "white" }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={t("real-time-page.date_picker.end")}
                        name="endDate"
                        value={readDate(endDate)}
                        format="DD/MM/YYYY"
                        onChange={(date) => handleEndDateChange(date)}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                        minDate={readDate(startDate)}
                        sx={{ bgcolor: "white" }}
                        maxDate={readDate(end_time)}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <Paper
                    variant="outlined"
                    sx={{ height: "100%", padding: "20px", width: "100%" }}
                  >
                    <h3>{t("real-time-page.sensor-data")}</h3>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="sensor-select-label">
                            {t("real-time-page.input_label_sensor")}
                          </InputLabel>
                          <Select
                            labelId="sensor-select-label"
                            id="sensor-select"
                            label="Sensor"
                            value={selectedSensor}
                            onChange={handleSensorChange}
                          >
                            {sensors_data &&
                              sensors_data.map((option, i) => (
                                <MenuItem key={i} value={option.id_thing}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="measure-select-label">
                            {t("real-time-page.input_label_measure")}
                          </InputLabel>
                          <Select
                            labelId="measure-select-label"
                            id="measure-select"
                            label="Measure"
                            value={selectedMeasure}
                            onChange={handleMeasureChange}
                            disabled={!selectedSensor}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                },
                              },
                            }}
                          >
                            {selectedSensor &&
                              datastreamsMeasure.map((option, i) => (
                                <MenuItem key={i} value={option.name}>
                                  {option.observed_property}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {selectedMeasure && (
                      <div>
                        {sensorChartData?.datasets?.[0]?.data?.length > 0 ? (
                          <Grid item xs={12}>
                            <h4>{t("real-time-page.chart_sensor_title")}</h4>
                            <LineChartComponent
                              data={sensorChartData}
                              unit={unit}
                            ></LineChartComponent>
                          </Grid>
                        ) : (
                          <NoDataPlaceholder
                            message={t("real-time-page.no-data-available")}
                          />
                        )}
                      </div>
                    )}
                    {selectedSensor && (
                      <>
                        <h4>{t("real-time-page.table_data_sensor")}</h4>
                        {Array.isArray(filteredLastMeasure) &&
                        filteredLastMeasure.length > 0 ? (
                          <CustomizedTables
                            rows={filteredLastMeasure.map(
                              ({
                                datastream_name,
                                value,
                                unit_of_measurement,
                              }) => ({
                                name: datastream_name,
                                value: `${value} ${extractUnitSymbol(
                                  unit_of_measurement
                                )}`,
                              })
                            )}
                            tableTitle={
                              lastMeasure?.datastream_name ||
                              "Ultime misurazioni"
                            }
                          />
                        ) : (
                          <NoDataPlaceholder
                            message={t("real-time-page.no-data-available")}
                          />
                        )}
                      </>
                    )}
                  </Paper>
                  <WeatherStationComponent
                    weatherStation={field?.weather_station}
                    getLabels={getLabels}
                    getDatasets={getDatasets}
                    end_time={endDate}
                    start_time={startDate}
                    field={field}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </Fragment>
  );
};

export default RealtimePage;
