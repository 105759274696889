export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_REQUESTFAILURE";

export const resetPasswordRequest = (data) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: data,
});
export const resetPasswordSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    error: error,
    payload: null,
  };
};
