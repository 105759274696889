export const sortIrrigationsByDateAndTime = (irrigations) => {
  return irrigations?.sort((a, b) => {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);

    if (dateA.getTime() === dateB.getTime()) {
      const timeA = a?.time || "";
      const timeB = b?.time || "";
      return timeB.localeCompare(timeA);
    }

    return dateB - dateA;
  });
};
