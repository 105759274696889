import { useTheme } from "@emotion/react";
import { Button, Container, Grid, Paper } from "@mui/material";
import { Fragment } from "react";
import logo from "../assets/images/Acquaount-logo.png";
import { useTranslation } from "react-i18next";
import ForgotPasswordComponent from "shared-components/src/components/forgotPassword/forgotPassword.component";
import { forgotPasswordRequest } from "shared-components/src/components/forgotPassword/store/forgotPassword.actions";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Fragment>
      <div
        style={{
          background: theme.palette.primary.main,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={5}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <ForgotPasswordComponent
                  logo={logo}
                  onForgotPassword={forgotPasswordRequest}
                />
                <Grid container spacing={2} style={{ marginTop: "5px" }}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      href="/login"
                    >
                      {t("login")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordPage;
