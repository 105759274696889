export const FETCH_WEATHER_STATIONS_REQUEST = "FETCH_WEATHER_STATIONS_REQUEST";
export const FETCH_WEATHER_STATIONS_SUCCESS = "FETCH_WEATHER_STATIONS_SUCCESS";
export const FETCH_WEATHER_STATIONS_FAILURE = "FETCH_WEATHER_STATIONS_FAILURE";

export const fetchWeatherStationsRequest = () => {
  return {
    type: FETCH_WEATHER_STATIONS_REQUEST,
  };
};

export const fetchWeatherStationsSuccess = (data) => {
  return {
    type: FETCH_WEATHER_STATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchWeatherStationsFailure = (error) => {
  return {
    type: FETCH_WEATHER_STATIONS_FAILURE,
    payload: error,
  };
};
