import {
  ADD_SOIL_PREPARATION,
  ADD_SOIL_PREPARATION_FAILURE,
  ADD_SOIL_PREPARATION_SUCCESS,
  DELETE_SOIL_PREPARATION,
  DELETE_SOIL_PREPARATION_FAILURE,
  DELETE_SOIL_PREPARATION_STATE,
  DELETE_SOIL_PREPARATION_SUCCESS,
  FETCH_SOIL_PREPARATIONS_FAILURE,
  FETCH_SOIL_PREPARATIONS_REQUEST,
  FETCH_SOIL_PREPARATIONS_SUCCESS,
} from "../actions/soilPreparations.actions";

const initialState = {
  data: null,
  loading: false,
};

const soil_preparation = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOIL_PREPARATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SOIL_PREPARATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_SOIL_PREPARATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_SOIL_PREPARATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_SOIL_PREPARATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_SOIL_PREPARATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_SOIL_PREPARATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_SOIL_PREPARATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SOIL_PREPARATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_SOIL_PREPARATION_STATE:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default soil_preparation;
