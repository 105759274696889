import { Box, Chip, Grid, Paper, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MapHomeComponent from "../components/map/MapHome.component";
import { useSelector } from "react-redux";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import HomeAreaChartComponent from "shared-components/src/components/charts/homeAreaChart.component";
import WeatherForecastComponent from "shared-components/src/components/weatherForecast/WeatherForecastNew.component";

const HomePage = () => {
  const { t } = useTranslation();
  const weatherForecast = useSelector((state) => state.weatherForecast).data;
  const position = useSelector(
    (state) => state.fields?.data?.[0]?.polygons?.[0]?.position?.[0]
  );
  const isMobile = useMediaQuery("(max-width: 767px");

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: "20px", marginTop: "5px" }}
      >
        {/* Weather and Overview */}
        <Grid item xs={12} md={4}>
          {weatherForecast && (
            <Paper
              variant="outlined"
              sx={{
                height: "100%",
                paddingX: "20px",
                paddingBottom: "20px",
                marginBottom: isMobile ? undefined : "10px",
              }}
            >
              <h3>{t("home.weather-forecast")}</h3>
              <div style={{ minHeight: "100%" }}>
                <WeatherForecastComponent position={position} />
              </div>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            variant="outlined"
            sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
          >
            <h3>{t("home.oveview")}</h3>
            <MapHomeComponent></MapHomeComponent>
          </Paper>
        </Grid>
        {/* Water forecast and planning */}
        <Grid item sx={12} md={4}>
          <Paper
            variant="outlined"
            sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
          >
            <h3>{t("home.planning")}</h3>
            <Box
              width="100%"
              height="400px"
              border="0.5px solid lightgrey"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
            >
              <Chip
                icon={<PrecisionManufacturingIcon />}
                label="Under development"
                variant="outlined"
                color="warning"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item sx={12} md={8}>
          {!isMobile && (
            <Paper
              variant="outlined"
              sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
            >
              <h3>{t("home.months-water-forecast")}</h3>
              <HomeAreaChartComponent />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
