export const FETCH_DATASTREAM_LIST_REQUEST = "FETCH_DATASTREAM_LIST_REQUEST";
export const FETCH_DATASTREAM_LIST_SUCCESS = "FETCH_DATASTREAM_LIST_SUCCESS";
export const FETCH_DATASTREAM_LIST_FAILURE = "FETCH_DATASTREAM_LIST_FAILURE";

export const fetchDatastreamListRequest = (systemName) => {
  return {
    type: FETCH_DATASTREAM_LIST_REQUEST,
    payload: systemName,
  };
};

export const fetchDatastreamListSuccess = (data) => {
  return {
    type: FETCH_DATASTREAM_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchDatastreamListFailure = (error) => {
  return {
    type: FETCH_DATASTREAM_LIST_FAILURE,
    payload: error,
  };
};
