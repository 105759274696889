import proj4 from "proj4";

const sourceCRS =
  "+proj=tmerc +x_0=1500000 +lon_0=-3.45233333 +k_0=0.9996 +ellps=intl +pm=12.45233333333333";
const targetCRS = "+proj=longlat +datum=WGS84 +no_defs";

proj4.defs("sourceCRS", sourceCRS);
proj4.defs("targetCRS", targetCRS);

export const convertGeoJSONCoordinatesFromShp = (geojsonData) => {
  return geojsonData.map((feature) => {
    if (feature.geometry && feature.geometry.coordinates) {
      const convertedCoordinates = feature.geometry.coordinates.map(
        (coordSet) => {
          return coordSet.map((coord) =>
            proj4("sourceCRS", "targetCRS", coord)
          );
        }
      );

      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: convertedCoordinates,
        },
      };
    }
    return feature;
  });
};

export const calculateCentroid = (polygon) => {
  if (!polygon || polygon.length === 0) return null;
  let sumLat = 0,
    sumLng = 0;
  const numPoints = polygon.length;
  polygon.forEach(([lat, lng]) => {
    sumLat += lat;
    sumLng += lng;
  });
  return [sumLat / numPoints, sumLng / numPoints];
};
