import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { API_RESET_PASSWORD } from "../../../../../acquaount-dashboard-farm/src/config/api";
import {
  resetPasswordSuccess,
  RESET_PASSWORD_REQUEST,
} from "../store/resetPassword.actions";

const resetPassword = async (data) => {
  const url = `${API_RESET_PASSWORD}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleResetPassword(action) {
  try {
    yield put(showProgress());
    const response = yield call(resetPassword, action?.payload);
    yield put(resetPasswordSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.message,
      })
    );
  }
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword);
}
