import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_ACTIVITIES, API_POPULATE_ALL } from "../../config/api";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import {
  ADD_ACTIVITY,
  FETCH_ACTIVITIES_REQUEST,
  addActivityFailure,
  addActivitySuccess,
  fetchActivityFailure,
  fetchActivitySuccess,
} from "../../store/actions/activities.actions";
import { fetchFieldsDataRequest } from "../../store/actions/fields.actions";

const addActivity = async (token, activity) => {
  const fieldsUrl = `${API_ACTIVITIES}`;
  const data = activity;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleAddActivity(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    let fieldId = action?.payload;
    const activity = { field: fieldId };
    const response = yield call(addActivity, token, activity);
    yield put(addActivitySuccess(response));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(addActivityFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchAddActivity() {
  yield takeLatest(ADD_ACTIVITY, handleAddActivity);
}

const getActivityData = async (token, id) => {
  const fieldsUrl = `${API_ACTIVITIES}/${id}${API_POPULATE_ALL}`;
  const response = await axios.get(fieldsUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleActivityData(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = action?.payload;
    yield put(showProgress());
    const response = yield call(getActivityData, token, farmId);
    yield put(fetchActivitySuccess(response?.data));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchActivityFailure(error.message));
  }
}

export function* watchGetActivity() {
  yield takeLatest(FETCH_ACTIVITIES_REQUEST, handleActivityData);
}
