export const FETCH_WEATHER_STATION_DATASTREAM_LIST_REQUEST =
  "FETCH_WEATHER_STATION_DATASTREAM_LIST_REQUEST";
export const FETCH_WEATHER_STATION_DATASTREAM_LIST_SUCCESS =
  "FETCH_WEATHER_STATION_DATASTREAM_LIST_SUCCESS";
export const FETCH_WEATHER_STATION_DATASTREAM_LIST_FAILURE =
  "FETCH_WEATHER_STATION_DATASTREAM_LIST_FAILURE";

export const fetchWeatherStationDatastreamListRequest = (systemName) => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_LIST_REQUEST,
    payload: systemName,
  };
};

export const fetchWeatherStationDatastreamListSuccess = (data) => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchWeatherStationDatastreamListFailure = (error) => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_LIST_FAILURE,
    payload: error,
  };
};
