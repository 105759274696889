import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertInfo } from "./store/alertInfo.actions";

const AlertInfoComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alertInfo = useSelector((state) => state.alertInfo);

  const closeAlert = () => {
    dispatch(hideAlertInfo());
  };

  useEffect(() => {
    if (alertInfo?.type) {
      //automatic close after 10 seconds
      const timer = setTimeout(() => {
        closeAlert();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alertInfo]);

  return (
    <Stack sx={{ width: "100%", marginBottom: "10px" }} spacing={2}>
      {alertInfo?.type && (
        <Alert
          //variant="outlined"
          severity={alertInfo?.type}
          onClose={() => {
            closeAlert();
          }}
        >
          {t(`irrigation_actions.errors.${alertInfo?.message}`)}
        </Alert>
      )}
    </Stack>
  );
};

export default AlertInfoComponent;
