import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeatherForecastDataRequest } from "./store/actions/weatherForecast.actions";
import WeatherWidget from "./WeatherWidget.component";

const selectWeatherData = (state) => ({
  weather: state?.weatherForecast?.data?.current?.condition?.text ?? "-",
  iconCurrent: state?.weatherForecast?.data?.current?.condition?.icon,
  iconForecast:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day?.condition
      ?.icon,
  maxTemp:
    state?.weatherForecast?.data?.forecast?.forecastday[0]?.day?.maxtemp_c ??
    "-",
  minTemp:
    state?.weatherForecast?.data?.forecast?.forecastday[0]?.day?.mintemp_c ??
    "-",
  humidity: state?.weatherForecast?.data?.current?.humidity ?? "-",
  rain: state?.weatherForecast?.data?.current?.precip_mm ?? "-",
  wind: state?.weatherForecast?.data?.current?.wind_kph ?? "-",
  windDirection: state?.weatherForecast?.data?.current?.wind_dir ?? "-",
  forecastDay:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.date ?? "-",
  forecastMinTemp:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day?.mintemp_c ??
    "-",
  forecastMaxTemp:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day?.maxtemp_c ??
    "-",
  forecastAvgHumidity:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day?.avghumidity ??
    "-",
  forecastTotalPrecip_mm:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day
      ?.totalprecip_mm ?? "-",
  forecastMaxWind_kph:
    state?.weatherForecast?.data?.forecast?.forecastday[1]?.day?.maxwind_kph ??
    "-",
  temp_c: state?.weatherForecast?.data?.current?.temp_c ?? "-",
  location: state?.weatherForecast?.data?.location?.name ?? "-",
});

const WeatherForecastComponent = ({ position }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    weather,
    iconCurrent,
    iconForecast,
    maxTemp,
    minTemp,
    humidity,
    rain,
    wind,
    windDirection,
    forecastMinTemp,
    forecastMaxTemp,
    forecastAvgHumidity,
    forecastTotalPrecip_mm,
    forecastMaxWind_kph,
    temp_c,
    location,
  } = useSelector(selectWeatherData);

  useEffect(() => {
    dispatch(fetchWeatherForecastDataRequest(position));
  }, [position, dispatch]);

  return (
    <Box height="auto" width="auto">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <WeatherWidget
            iconCurrent={iconCurrent}
            weather={weather}
            day={t("weather-forecast.today")}
            temperature={temp_c}
            minTemp={minTemp}
            maxTemp={maxTemp}
            humidity={humidity}
            rain={rain}
            wind={wind}
            windDirection={windDirection}
            location={location}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <WeatherWidget
            iconCurrent={iconForecast}
            weather={weather}
            day={t("weather-forecast.tomorrow")}
            minTemp={forecastMinTemp}
            maxTemp={forecastMaxTemp}
            humidity={forecastAvgHumidity}
            rain={forecastTotalPrecip_mm}
            wind={forecastMaxWind_kph}
            windDirection={windDirection}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeatherForecastComponent;
