import {
  ADD_FERTILIZER,
  ADD_FERTILIZER_FAILURE,
  ADD_FERTILIZER_SUCCESS,
  DELETE_FERTILIZER,
  DELETE_FERTILIZER_FAILURE,
  DELETE_FERTILIZER_STATE,
  DELETE_FERTILIZER_SUCCESS,
  FETCH_FERTILIZERS_FAILURE,
  FETCH_FERTILIZERS_REQUEST,
  FETCH_FERTILIZERS_SUCCESS,
} from "../actions/fertilizers.actions";

const initialState = {
  data: null,
  loading: false,
};

const fertilizer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FERTILIZERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FERTILIZERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_FERTILIZERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_FERTILIZER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_FERTILIZER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_FERTILIZER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_FERTILIZER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_FERTILIZER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_FERTILIZER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_FERTILIZER_STATE:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default fertilizer;
