export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const signupRequest = (data) => ({
  type: SIGNUP_REQUEST,
  payload: data,
  alreadyRegistered: null,
});

export const signupSuccess = (data) => {
  return {
    type: SIGNUP_SUCCESS,
    payload: data,
  };
};

export const signupFailure = (error, alreadyRegistered) => {
  return {
    type: SIGNUP_FAILURE,
    error: error,
    payload: null,
    alreadyRegistered: alreadyRegistered,
  };
};
