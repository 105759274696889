import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Unit",
      },
    },
  },
};

const labels = [];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "",
      backgroundColor: "",
    },
  ],
};

const LineChartComponent = (props) => {
  const linearChartData = props.data ?? data;
  const linearChartOptions = {
    ...options,
    scales: {
      y: {
        title: {
          display: true,
          text: props.unit || "Unit", // Usa l'unità passata tramite props o "Unità" come default
        },
      },
    },
    ...props.options, // Sovrascrive altre opzioni se passate
  };

  return <Line options={linearChartOptions} data={linearChartData} />;
};

export default LineChartComponent;
