import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { setField } from "../../store/actions/field.actions";
import { useDispatch, useSelector } from "react-redux";

const FieldSelectorComponent = (props) => {
  const { t } = useTranslation();
  const fieldSelected = useSelector((state) => state.field.data);
  const fields = useSelector((state) => state.fields.data);
  const [value, setValue] = React.useState(fieldSelected ?? "");

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(setField(event.target.value));
  };

  const label = t("field-selector.label");

  return (
    <Box sx={{ minWidth: 120 }}>
      {!fieldSelected && (
        <div
          style={{ fontWeight: "bold", marginBottom: "5px", fontSize: "11px" }}
        >
          {t("field-selector.select-field")}
        </div>
      )}
      <FormControl fullWidth>
        <InputLabel id="field-select-label">{label}</InputLabel>
        <Select
          labelId="field-select-label"
          id="field-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {fields &&
            fields.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FieldSelectorComponent;
