import {
  SEND_COMMAND_FAILURE,
  SEND_COMMAND_REQUEST,
  SEND_COMMAND_SUCCESS,
  GET_COMMAND_STATUS_FAILURE,
  GET_COMMAND_STATUS_SUCCESS,
  GET_COMMAND_STATUS_REQUEST,
} from "../actions/irrigationCommand.actions";

const initialState = {
  data: {
    sendResponse: null,
    commandStatus: null,
  },
  loading: false,
};

const irrigationCommandData = (state = initialState, action) => {
  switch (action.type) {
    case SEND_COMMAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_COMMAND_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          sendResponse: action.payload,
        },
        loading: false,
      };
    case SEND_COMMAND_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_COMMAND_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMAND_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          commandStatus: action.payload,
        },
        loading: false,
      };
    case GET_COMMAND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default irrigationCommandData;
