export const FETCH_PESTICIDES_REQUEST = "FETCH_PESTICIDES_REQUEST";
export const FETCH_PESTICIDES_SUCCESS = "FETCH_PESTICIDES_SUCCESS";
export const FETCH_PESTICIDES_FAILURE = "FETCH_PESTICIDES_FAILURE";
export const ADD_PESTICIDE = "ADD_PESTICIDE";
export const ADD_PESTICIDE_SUCCESS = "ADD_PESTICIDE_SUCCESS";
export const ADD_PESTICIDE_FAILURE = "ADD_PESTICIDE_FAILURE";
export const UPDATE_PESTICIDE = "UPDATE_PESTICIDE";
export const UPDATE_PESTICIDE_SUCCESS = "UPDATE_PESTICIDE_SUCCESS";
export const UPDATE_PESTICIDE_FAILURE = "UPDATE_PESTICIDE_FAILURE";
export const DELETE_PESTICIDE = "DELETE_PESTICIDE";
export const DELETE_PESTICIDE_SUCCESS = "DELETE_PESTICIDE_SUCCESS";
export const DELETE_PESTICIDE_FAILURE = "DELETE_PESTICIDE_FAILURE";
export const DELETE_PESTICIDE_STATE = "DELETE_PESTICIDE_STATE";

export const addPesticideRequest = (data) => {
  return {
    type: ADD_PESTICIDE,
    payload: data,
  };
};

export const addPesticideSuccess = (data) => {
  return {
    type: ADD_PESTICIDE_SUCCESS,
    payload: data,
  };
};

export const addPesticideFailure = () => {
  return {
    type: ADD_PESTICIDE_FAILURE,
  };
};

export const fetchPesticideRequest = (farmId) => {
  return {
    type: FETCH_PESTICIDES_REQUEST,
    payload: farmId,
  };
};

export const fetchPesticideSuccess = (data) => {
  return {
    type: FETCH_PESTICIDES_SUCCESS,
    payload: data,
  };
};

export const fetchPesticideFailure = () => {
  return {
    type: FETCH_PESTICIDES_FAILURE,
  };
};

export const deletePesticideRequest = (data) => {
  return {
    type: DELETE_PESTICIDE,
    payload: data,
  };
};

export const deletePesticideSuccess = (data) => {
  return {
    type: DELETE_PESTICIDE_SUCCESS,
    payload: data,
  };
};

export const deletePesticideFailure = () => {
  return {
    type: DELETE_PESTICIDE_FAILURE,
  };
};

export const deletePesticideState = () => {
  return {
    type: DELETE_PESTICIDE_STATE,
  };
};
