import React, { useState } from "react";
import { Button, CircularProgress, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as shapefile from "shapefile";

const FileUploader = ({ onUploadSuccess }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [, setFeatures] = useState(null);
  const [prjFile, setPrjFile] = useState(null);
  const [isCrsValid, setIsCrsValid] = useState(true);

  const handleFileChange = (event) => {
    const selectedFile = Array.from(event.target.files);
    const shpFile = selectedFile.find((f) => f.name.endsWith(".shp"));
    const prjFile = selectedFile.find((f) => f.name.endsWith(".prj"));
    setPrjFile(prjFile);
    setFile(shpFile);
    setShowAlert(false);

    if (prjFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const prjContent = e.target.result.trim();
        if (!prjContent.toUpperCase().includes("WGS84")) {
          setAlertMessage(t("shapefile.invalid_crs"));
          setShowAlert(true);
          setIsCrsValid(false);
        } else {
          setIsCrsValid(true);
        }
      };
      reader.readAsText(prjFile);
    }
  };

  const handleUpload = () => {
    if (!file) {
      setAlertMessage(t("shapefile_page.alert_not_file"));
      setShowAlert(true);
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "shp") {
      setAlertMessage(t("shapefile.alert_invalid_file"));
      setShowAlert(true);
      return;
    }

    if (!isCrsValid || !prjFile) {
      setAlertMessage(t("shapefile.invalid_crs"));
      setShowAlert(true);
      return;
    }

    setIsLoading(true);

    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const reader = await shapefile.open(arrayBuffer);
        const features = [];

        let result = await reader.read();
        while (!result.done) {
          features.push(result.value);
          result = await reader.read();
        }

        setFeatures(features);
        sessionStorage.setItem("shapefileData", JSON.stringify(features)); // Salva nella sessione
        setIsLoading(false);
        setShowAlert(true);
        setAlertMessage(t("shapefile.upload_success"));

        if (onUploadSuccess) {
          onUploadSuccess(features);
        }
      };

      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Errore nel caricamento dello shapefile:", error);
      setIsLoading(false);
      setAlertMessage(t("shapefile.upload_error"));
    }
  };

  return (
    <div>
      <input
        type="file"
        id="shapefile-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
      <label htmlFor="shapefile-input" style={{ marginRight: 8 }}>
        <Button variant="contained" component="span">
          {t("shapefile.select_file")}
        </Button>
      </label>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!file}
      >
        {t("shapefile.upload")}
      </Button>
      {isLoading && <CircularProgress />}
      {showAlert && (
        <Alert
          severity={
            alertMessage === t("shapefile.upload_success") ? "success" : "error"
          }
          onClose={() => setShowAlert(false)}
          sx={{ width: "fit-content" }}
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
};

export default FileUploader;
