import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AlertInfoComponent from "shared-components/src/components/feedback/AlertInfo.component";
import * as Yup from "yup";
import { sensorType } from "../../config/sensorTypeEnum";
import {
  addSensorRequest,
  deleteSensorRequest,
} from "../../store/actions/sensors.actions";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import UpdateSensorComponent from "./updateSensor.component";
import MapDrawMarkerComponent from "shared-components/src/components/map/mapDrawMarker.component";

const SensorsFormComponent = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { values, setFieldValue, handleChange } = props?.formikProps;
  const [sensors, setSensors] = useState(values?.sensors ?? []);
  const [newSensorType, setSensorType] = useState("");
  const dispatch = useDispatch();
  const sensorLoading = useSelector((state) => state?.sensors?.loading);
  const sensorAddError = useSelector((state) => state?.sensors?.error);
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);
  const [sensorToUpdate, setSensorToUpdate] = useState(null);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(null);

  const initialValues = {
    type: "",
    eui: "",
    position: [],
    label: "",
    id_thing: "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    eui: Yup.string().required("Required"),
    latitude: Yup.string()
      .required("Required")
      .test("is-valid-latitude", "Invalid latitude format", function (value) {
        if (!value) return false;
        const floatValue = parseFloat(value);
        return (
          !isNaN(floatValue) &&
          floatValue >= -90 &&
          floatValue <= 90 &&
          value.includes(".")
        );
      }),
    longitude: Yup.string()
      .required("Required")
      .test("is-valid-longitude", "Invalid longitude format", function (value) {
        if (!value) return false;
        const floatValue = parseFloat(value);
        return (
          !isNaN(floatValue) &&
          floatValue >= -180 &&
          floatValue <= 180 &&
          value.includes(".")
        );
      }),
    label: Yup.string().required("Required"),
    id_thing: Yup.string().required("Required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      const newSensor = {
        type: value.type,
        eui: value.eui,
        position: [value.latitude, value.longitude],
        label: value.label,
        id_thing: value.id_thing,
      };

      dispatch(addSensorRequest(newSensor)).catch((error) => {
        console.error("Errore durante l'aggiunta del sensore:", error);
      });
    }
  };

  useEffect(() => {
    if (!sensorLoading && !sensorAddError) {
      setOpen(false);
    }
  }, [sensorLoading, sensorAddError]);

  useEffect(() => {
    if (field?.sensors) {
      setSensors(field?.sensors);
      setFieldValue("sensors", field?.sensors);
      setObjectToDelete(null);
    }
  }, [field, setFieldValue]);

  const deleteSensor = (value) => {
    console.info("deleteSensor", value);
    if (value) {
      dispatch(deleteSensorRequest(value));
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteSensor(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  const handleUpdateClick = (sensor) => {
    setSensorToUpdate(sensor);
    setIsUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = () => {
    setIsUpdateDialogOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setSensorToUpdate(null);
    setIsUpdateDialogOpen(false);
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>{t("sensors_form.p")}</p>
            <h5 onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("add_sensor")}
            </h5>
          </div>
        </Grid>
        <Grid item xs={12}>
          {sensors.map((sensor, index) => (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              key={index}
              style={{
                background: "#f6f6f6",
                width: "100%",
                paddingBottom: "20px",
                marginBottom: "60px",
                marginLeft: "5px",
                paddingRight: "15px",
                color: "#000000",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    onClick={() => handleUpdateClick(sensor)}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    <CreateIcon fontSize="small" />
                  </div>
                  <div
                    onClick={() => handleDeleteClick(sensor)}
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteIcon fontSize="small" />
                  </div>
                </div>
              </Grid>
              <input
                type="hidden"
                value={`sensors[${index}].id`}
                name={`sensors[${index}].id`}
              />
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">
                    {t("sensors_form.input_label_type")}
                  </InputLabel>
                  <Select
                    label={t("sensors_form.select_label_type")}
                    id={`sensors[${index}].type`}
                    name={`sensors[${index}].type`}
                    labelId="type-select-label"
                    value={values?.sensors[index].type}
                    disabled={true}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>{t("sensors_form.em")}</em>
                    </MenuItem>
                    {Object.values(sensorType).map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage name="crop.species" component="div" />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("sensors_form.field_label_device")}
                  name={`sensors[${index}].eui`}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
                <ErrorMessage name={`sensors[${index}].eui`} component="div" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("sensors_form.field_label_latitude")}
                  name={`sensors[${index}].position[0]`}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
                <ErrorMessage
                  name={`sensors[${index}].position[0]`}
                  component="div"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("sensors_form.field_label_longitude")}
                  name={`sensors[${index}].position[1]`}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
                <ErrorMessage
                  name={`sensors[${index}].position[1]`}
                  component="div"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("sensors_form.field_label_name")}
                  name={`sensors[${index}].label`}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
                <ErrorMessage
                  name={`sensors[${index}].label`}
                  component="div"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  as={TextField}
                  label={t("sensors_form.field_label_id_thing")}
                  name={`sensors[${index}].id_thing`}
                  variant="outlined"
                  fullWidth
                  disabled={true}
                />
                <ErrorMessage
                  name={`sensors[${index}].id_thing`}
                  component="div"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "70%",
          },
        }}
      >
        <AlertInfoComponent />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(formikDialogProps) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t("add_sensor")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <InputLabel id="type-select-label">
                              {t("sensors_form.input_label_type")}
                            </InputLabel>
                            <Select
                              label={t("sensors_form.select_label_type")}
                              id={`type`}
                              name={`type`}
                              labelId="type-select-label"
                              value={newSensorType}
                              onChange={(e) => {
                                setSensorType(e.target.value);
                                formikDialogProps.setFieldValue(
                                  "type",
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem value="">
                                <em>{t("none")}</em>
                              </MenuItem>
                              {Object.values(sensorType).map((option, i) => (
                                <MenuItem key={i} value={option}>
                                  {t(option)}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="crop.species" component="div" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t("sensors_form.field_label_device")}
                            name={`eui`}
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage name={`eui`} component="div" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            type="text"
                            label={t("sensors_form.field_label_latitude")}
                            name={`latitude`}
                            variant="outlined"
                            fullWidth
                            error={
                              formikDialogProps.errors.latitude &&
                              formikDialogProps.touched.latitude
                            }
                            helperText={
                              formikDialogProps.errors.latitude &&
                              formikDialogProps.touched.latitude
                                ? formikDialogProps.errors.latitude
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            type="text"
                            label={t("sensors_form.field_label_longitude")}
                            name={`longitude`}
                            variant="outlined"
                            fullWidth
                            error={
                              formikDialogProps.errors.longitude &&
                              formikDialogProps.touched.longitude
                            }
                            helperText={
                              formikDialogProps.errors.longitude &&
                              formikDialogProps.touched.longitude
                                ? formikDialogProps.errors.longitude
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t("sensors_form.field_label_name")}
                            name={`label`}
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage name={`label`} component="div" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t("sensors_form.field_label_id_thing")}
                            name={`id_thing`}
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage name={`id_thing`} component="div" />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <MapDrawMarkerComponent
                        polygons={field.polygons}
                        markerPosition={{
                          lat: formikDialogProps?.values?.latitude,
                          lng: formikDialogProps?.values?.longitude,
                        }}
                        onMarkerPositionChange={(value) => {
                          formikDialogProps.setFieldValue(
                            "latitude",
                            value?.lat || ""
                          );
                          formikDialogProps.setFieldValue(
                            "longitude",
                            value?.lng || ""
                          );
                        }}
                      ></MapDrawMarkerComponent>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("add_sensor")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      {sensorToUpdate && (
        <UpdateSensorComponent
          sensor={sensorToUpdate}
          open={isUpdateDialogOpen}
          onClose={handleUpdateDialogClose}
          onConfirm={handleConfirmUpdate}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default SensorsFormComponent;
