import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const calculateAmount = (amount, irrigationAmount, irrigationNumber) => {
  return (parseFloat(amount) * irrigationAmount * irrigationNumber).toFixed(2);
};

const calculateAmountFromDripSubterranean = (
  waterDispersed,
  wettedLength,
  spaceBetween,
  amount
) => {
  if (!waterDispersed || !wettedLength || !spaceBetween || !amount) {
    throw new Error("Tutti i parametri devono essere validi e maggiori di 0.");
  }

  const cubicMeters =
    ((waterDispersed * (wettedLength / (spaceBetween / 100))) / 1000) * amount;

  return cubicMeters.toFixed(3);
};

const calculateAmountFromSufaceIrrigation = (amount, irrigationAmount) => {
  return (parseFloat(amount) * irrigationAmount).toFixed(2);
};

export const formatDate = (date) => {
  return dayjs.utc(date).format("HH:mm DD/MM/YY");
};

export const irrigationHandlers = (field) => ({
  sprinkler: (value) => {
    const irrigationAmount = field?.irrigations_system?.s_rate;
    const irrigationNumber = field?.irrigations_system?.number;
    return calculateAmount(value.amount, irrigationAmount, irrigationNumber);
  },
  drip: (value) => {
    const waterDispersed = field?.irrigations_system?.d_rate;
    const wettedLength = field?.irrigations_system?.wetted_length;
    const spaceBetween = field?.irrigations_system?.space_driping_bores;
    return calculateAmountFromDripSubterranean(
      waterDispersed,
      wettedLength,
      spaceBetween,
      value.amount
    );
  },
  subterranean: (value) => {
    const waterDispersed = field?.irrigations_system?.d_rate;
    const wettedLength = field?.irrigations_system?.wetted_length;
    const spaceBetween = field?.irrigations_system?.spacing_dripping_bore;
    return calculateAmountFromDripSubterranean(
      waterDispersed,
      wettedLength,
      spaceBetween,
      value.amount
    );
  },
  surface_irrigation: (value) => {
    const irrigationAmount = field?.irrigations_system?.surface_irrigation_rate;
    return calculateAmountFromSufaceIrrigation(value.amount, irrigationAmount);
  },
});

export const calculateAmountFromTime = (hours, minutes, flowRate) => {
  const totalHours = hours + minutes / 60;
  return (totalHours * flowRate).toFixed(2);
};

export const calculateTimeFromAmount = (amount, flowRate) => {
  const totalHours = amount / flowRate;
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  return { hours, minutes };
};

export const calculateIrrigationDuration = (endTimestamp, startTimestamp) => {
  const end = dayjs(endTimestamp);
  const start = dayjs(startTimestamp);

  const diffMs = end.diff(start);

  return Math.round(diffMs / 60000);
};

const STATUS_COLORS = {
  READY: "green",
  EXECUTED: "#e97132",
  WAIT: "#04b1f1",
  UNKNOWN: "gray",
};

export function getStatusInfo(status, t) {
  switch (status) {
    case "processed":
      return {
        label: t("irrigation_actions.statusInfo.label_ready"),
        color: STATUS_COLORS.READY,
        message: t("irrigation_actions.statusInfo.message_ready"),
      };
    case "sent":
      return {
        label: t("irrigation_actions.statusInfo.label_executed"),
        color: STATUS_COLORS.EXECUTED,
        message: t("irrigation_actions.statusInfo.message_executed"),
      };
    case "processing":
      return {
        label: t("irrigation_actions.statusInfo.processing"),
        color: STATUS_COLORS.WAIT,
        message: "",
      };
    default:
      return {
        label: "Stato Sconosciuto",
        color: STATUS_COLORS.UNKNOWN,
        message: "Stato non riconosciuto",
      };
  }
}
