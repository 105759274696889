export const FETCH_WEATHER_STATION_DATASTREAM_MEASURE_REQUEST =
  "FETCH_WEATHER_STATION_DATASTREAM_MEASURE_REQUEST";
export const FETCH_WEATHER_STATION_DATASTREAM_MEASURE_SUCCESS =
  "FETCH_WEATHER_STATION_DATASTREAM_MEASURE_SUCCESS";
export const FETCH_WEATHER_STATION_DATASTREAM_MEASURE_FAILURE =
  "FETCH_WEATHER_STATION_DATASTREAM_MEASURE_FAILURE";

export const fetchWeatherStationDatastreamMeasureDataRequest = (payload) => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_MEASURE_REQUEST,
    payload,
  };
};

export const fetchWeatherStationDatastreamMeasureDataSuccess = (data) => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchWeatherStationDatastreamMeasureDataFailure = () => {
  return {
    type: FETCH_WEATHER_STATION_DATASTREAM_MEASURE_FAILURE,
  };
};
