const host = process.env.REACT_APP_BACKEND_URL || "http://localhost:1337";
// const host = "https:///backend.acquaount.development.abidevops.website";
const eurecatHost = "https://84.88.76.18/wot";

export const weatherApiKey = "c45d67942b6f42a4ac3123410240807";
/* export const customTokenKey =
  process.env.REACT_APP_CUSTOM_TOKEN_KEY ||
  "753c44a7a93de237a403842077ad08aecd108723e50e0ab2ba96d7bcb235551c9895b3f39ad3dea947c069ecd72ec8ed6a6af79497303b47703a69d3467d6d17a8407541ae5505e1d6756d16d9d64c4cfc296514365789c59303e22cbc17d66596394add10e3439fef10b6884dcbfd1cc814bfb6dcd5813be0a6578b5bc24a65"; */
export const customTokenKey =
  process.env.REACT_APP_CUSTOM_TOKEN_KEY ||
  "1894cec70c7ade3c57cec17b5add1f2f49ae2e4653f1ecead85cb2ebfb1bffb0557939f5a977da62f3609db88123b0b9f5faa89902695aee4515f280458bf5bb2d5e7f9d10ab99e058aafe412edd95f789eeb33c19535b8c67930cca1f472a273a1070e0bb3853768089eb4fbfe3a4e2767aa95ff1b8d00128ea2ecc3786b446";

const api_root = "/api";
export const API_URL_LOGIN = `${host}${api_root}/auth/local`;
export const API_GET_ME = `${host}${api_root}/users/me`;
export const API_WATER_AUTHORITY = `${host}${api_root}/water-authorities`;
export const API_WATER_SYSTEMS = `${host}${api_root}/water-systems`;
export const API_GET_FIELDS_DATA = `${host}${api_root}/fieldsFromFarm`;
export const API_ADD_FIELD_DATA = `${host}${api_root}/fields`;
export const API_UPDATE_FIELD_DATA = `${host}${api_root}/fields`;
export const API_POPULATE_ALL = `?populate=*`;
export const API_SENSORS = `${host}${api_root}/sensors`;
export const API_WEATHER_DATA = `${host}${api_root}/get-weather-data`;
export const API_SENSOR_MEASURE_DATA = `${host}${api_root}/get-measures-by-time-range`;
export const API_ALL_SENSORS_MEASURE = `${host}${api_root}/get-all-sensors-measure`;
export const API_DATA_FROM_MODEL = `${host}${api_root}/get-data-from-model`;
export const API_SIGNUP = `${host}${api_root}/auth/local/register`;
export const API_GET_USERS = `${host}${api_root}/users`;
export const API_FORGOT_PASSWORD = `${host}${api_root}/auth/forgot-password`;
export const API_RESET_PASSWORD = `${host}${api_root}/auth/reset-password`;
export const API_GET_DATASTREAMS_LIST = `${host}${api_root}/get-datastreams-list`;
export const API_GET_DATASTREAM_AGGREGATE_MEASURE = `${host}${api_root}/get-datastream-aggregate-measure`;
export const API_EURECAT = `${eurecatHost}`;
