const palette_custom = {
  primary: "#13482f",
  secondary: "#e7edeb",
  accent: "#ea7c3c",
  text: "#000000",
  background: "#f6f6f6",
  check: "#4674c2",
  error: "#fd7777",
  risk: "#fce59e",
  late: "#fd7777",
  optimal: "#c4dfb4",
  marker: "#245c42",
  success: "#4caf50",
};

export default palette_custom;
