import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { API_FORGOT_PASSWORD } from "../../../../../acquaount-dashboard-water/src/config/api";
import {
  forgotPasswordSuccess,
  FORGOT_PASSWORD_REQUEST,
  forgotPasswordFailure,
} from "../store/forgotPassword.actions";

const forgotPassword = async (data) => {
  const url = `${API_FORGOT_PASSWORD}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleForgotPassword(action) {
  try {
    yield put(showProgress());
    const response = yield call(forgotPassword, action?.payload);
    yield put(forgotPasswordSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.message,
      })
    );
    yield put(hideProgress());
    console.log("Forgot password error", error);
    yield put(forgotPasswordFailure(error.message));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, handleForgotPassword);
}
