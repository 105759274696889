import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import createCustomIcon from "./createCustomIcon";

const MapDrawMarkerComponent = (props) => {
  const mapRef = useRef(null);

  const mapHeight = props.mapHeight ?? "400px";
  const mapZoom = props.mapZoom ?? 7;
  const defaultCenter = [40.356862371325384, 9.240084014976134];
  const mapCenter =
    props.markerPosition && props.markerPosition.lat && props.markerPosition.lng
      ? [props.markerPosition.lat, props.markerPosition.lng]
      : props.mapCenter ?? defaultCenter;
  const mapUrl =
    props.mapUrl ?? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const attribution =
    props.attribution ??
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  const { polygons } = props;
  const { markerPosition, onMarkerPositionChange } = props;
  const [drawMarkerEnabled, setDrawMarkerEnabled] = useState(markerPosition);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      if (markerPosition && markerPosition.lat && markerPosition.lng) {
        map.setView([markerPosition.lat, markerPosition.lng], mapZoom);
      } else if (map._layers) {
        const allLayerBounds = Object.values(map._layers)
          .filter((layer) => layer._bounds)
          .map((layer) => layer._bounds);

        if (allLayerBounds.length > 0) {
          const allBounds = L.latLngBounds(allLayerBounds);
          if (allBounds.isValid()) {
            map.fitBounds(allBounds);
          }
        }
      }
    }
  }, [markerPosition, mapZoom]);

  const onCreated = (e) => {
    if (e.layerType === "marker") {
      const { lat, lng } = e.layer.getLatLng();
      const newMarkerPosition = { lat, lng };
      onMarkerPositionChange(newMarkerPosition);
      mapRef.current.removeLayer(e.layer);
      setDrawMarkerEnabled(false);
    }
  };

  const onDeleted = (e) => {
    onMarkerPositionChange(null);
    setDrawMarkerEnabled(true);
  };

  // Aggiungi l'evento `dragend` al marker per aggiornare la posizione
  const handleMarkerDragEnd = (e) => {
    const { lat, lng } = e.target.getLatLng();
    const updatedMarkerPosition = { lat, lng };
    onMarkerPositionChange(updatedMarkerPosition); // Salva la nuova posizione nel form
  };
  return (
    <MapContainer
      center={mapCenter}
      zoom={mapZoom}
      style={{ height: mapHeight }}
      ref={mapRef}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="ArcGIS">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com">Esri</a> | Abinsula'
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer url={mapUrl} attribution={attribution} />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Opentopomap">
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.opentopomap.org/copyright">OpenTopoMap</a> | Abinsula'
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      <FeatureGroup>
        {false &&
          polygons &&
          polygons.map((polygon) => (
            <Polygon
              key={polygon.id}
              id={polygon.id}
              positions={polygon.position}
              color={polygon.color}
            ></Polygon>
          ))}

        {markerPosition && markerPosition.lat && markerPosition.lng && (
          <Marker
            position={markerPosition}
            icon={createCustomIcon({ number: " ", color: "blue" })}
            draggable={true} // Rende il marker trascinabile
            eventHandlers={{
              dragend: handleMarkerDragEnd, // Listener per l'evento di drag
            }}
          />
        )}

        <EditControl
          position="topright"
          onCreated={onCreated}
          onDeleted={onDeleted}
          draw={{
            rectangle: false,
            circle: false,
            marker: drawMarkerEnabled,
            polyline: false,
            circlemarker: false,
            polygon: false,
          }}
          edit={{
            edit: false,
            remove: true,
          }}
        />
      </FeatureGroup>
    </MapContainer>
  );
};

export default MapDrawMarkerComponent;
