import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_GET_FARM_DATA } from "../../config/api";
import {
  ADD_FARM,
  FETCH_FARM_REQUEST,
  fetchFarmDataFailure,
  fetchFarmDataSuccess,
} from "../../store/actions/farm.actions";
import { meRequest } from "shared-components/src/components/users/store/users.actions";
import { logout } from "shared-components/src/components/login/store/auth.actions";

const getFarmData = async (token, farmId) => {
  const farmUrl = `${API_GET_FARM_DATA}/${farmId}`;
  const response = await axios.get(farmUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleFarmData(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = action?.payload;
    const response = yield call(getFarmData, token, farmId);
    yield put(fetchFarmDataSuccess(response.data));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchFarmDataFailure(error.message));
  }
}

export default function* watchGetFarm() {
  yield takeLatest(FETCH_FARM_REQUEST, handleFarmData);
}

const addFarm = async (token, activity) => {
  const url = `${API_GET_FARM_DATA}`;
  const data = activity;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, { data }, { headers });
  return response.data;
};

function* handleAddFarm(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farm = action?.payload;
    const response = yield call(addFarm, token, farm);
    yield put(fetchFarmDataSuccess(response));
    yield put(meRequest());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(fetchFarmDataFailure(error.message));
    yield put(hideProgress());
    yield put(logout());
  }
}

export function* watchAddFarm() {
  yield takeLatest(ADD_FARM, handleAddFarm);
}
