export const fieldInitialValues = {
  id: null,
  name: "",
  weather_station: "",
  polygons: [
    { id: null, position: [], color: "blue", popup: null, tooltip: null },
  ],
  irrigations_system: {
    system: "",
  },
  soil_type: {
    clay: "",
    silt: "",
    sand: "",
    field_capacity: "",
    permanent_wilting_point: "",
    bulk_density: "",
    soil_porosity: "",
    climate_zone: "",
    water_content_saturation: "",
    water_content_saturation_by_sensor: "",
    saturated_hydraulic_conductivity: "",
  },
  crop: {
    type: "",
    species: "",
    variety_name: "",
    variety_code: "",
    distance_line: "",
    distance_individuals: "",
    planting_date: "",
    flowering_date: "",
    rieping_date: "",
    expected_harvest_date: "",
    real_harvest_date: "",
    crop_yields: "",
  },
  sensors: [],
  activities: {
    irrigation: [],
    soil_preparation: [],
    fertilizer: [],
    pesticide: [],
  },
  realtime: {
    irrigation_profile: {},
    transpiration_profile: {},
    suggested_irrigation: {},
    sensors_data: [],
  },
};

export const irrigationSystem = {
  sprinkler: "sprinkler",
  drip: "drip",
  subterranean: "subterranean",
  surface_irrigation: "surface irrigation",
};

export const soilType = {
  texture: "texture",
  field_capacity: "field_capacity",
  permanent_wilting_point: "permanent_wilting_point",
  bulk_density: "bulk_density",
  soil_porosity: "soil_porosity",
  climate_zone: "climate_zone",
  water_content_saturation: "water_content_saturation",
  water_content_saturation_by_sensor: "water_content_saturation_by_sensor",
  saturated_hydraulic_conductivity: "saturated_hydraulic_conductivity",
  organic_matter: "organic_matter",
};

export const cropType = {
  annual: "annual",
  perennial: "perennial",
};

export const speciesType = {
  tomato: {
    crop: "tomato",
    type: 1,
  },
  maize: {
    crop: "maize",
    type: 1,
  },
  potato: {
    crop: "potato",
    type: 1,
  },
  orange: {
    crop: "orange",
    type: null,
  },
  onion: {
    crop: "onion",
    type: null,
  },
  wheat: {
    crop: "wheat",
    type: 1,
  },
  rice: {
    crop: "rice",
    type: 1,
  },
  barley: {
    crop: "barley",
    type: 1,
  },
  sorghum: {
    crop: "sorghum",
    type: 1,
  },
  soybean: {
    crop: "soybean",
    type: 1,
  },
  sunflower: {
    crop: "sunflower",
    type: 1,
  },
  lettuce: {
    crop: "lettuce",
    type: null,
  },
  carrot: {
    crop: "carrot",
    type: null,
  },
  watermelon: {
    crop: "watermelon",
    type: null,
  },
  citrus: {
    crop: "citrus",
    type: 2,
  },
  almond: {
    crop: "almond",
    type: null,
  },
  date_palm: {
    crop: "date palm",
    type: 2,
  },
  peach: {
    crop: "peach",
    type: null,
  },
  wine_grapes: {
    crop: "wine grapes",
    type: 2,
  },
  olives: {
    crop: "olives",
    type: 2,
  },
  cotton: {
    crop: "cotton",
    type: 1,
  },
  alfalfa: {
    crop: "alfalfa",
    type: 2,
  },
  pastures: {
    crop: "pastures",
    type: 2,
  },
};

export const cropAnnualKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_date",
  "flowering_date",
  "rieping_date",
  "expected_harvest_date",
  "real_harvest_date",
  "crop_yields",
];

export const cropPerennialKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_year",
  "flowering_date",
  "rieping_date",
  "expected_harvest_date",
  "real_harvest_date",
  "crop_yields",
];

export const soilTypeKeys = [
  "clay",
  "silt",
  "sand",
  "field_capacity",
  "permanent_wilting_point",
  "bulk_density",
  "soil_porosity",
  "climate_zone",
  "water_content_saturation",
  "water_content_saturation_by_sensor",
  "saturated_hydraulic_conductivity",
  "organic_matter",
];

export const irrigationsSystemKeys = ["system"];

export const irrigationsSystemSprinklerKeys = ["system", "number", "s_rate"];

export const irrigationsSystemDriprKeys = [
  "system",
  "number_driping_pipes",
  "space_driping_bores",
  "d_rate",
  "wetted_length",
];

export const irrigationsSystemSubterraneanKeys = [
  "system",
  "depth_pipes",
  "number_driping_pipes",
  "spacing_dripping_bore",
  "d_rate",
  "wetted_length",
];

export const irrigationsSystemSurfaceIrrigationKeys = [
  "system",
  "surface_irrigation_rate",
];

export const climateZoneOptions = {
  mediterranean: "Mediterranean",
  arid: "Arid",
};
