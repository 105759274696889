import axios from "axios";
import { API_GET_DATASTREAMS_LIST } from "../../../../../acquaount-dashboard-farm/src/config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "../../feedback/store/circularProgress.actions";
import { showAlertInfo } from "../../feedback/store/alertInfo.actions";
import { alertInfoTypes } from "../../feedback/alertInfoTypes.enum";
import {
  FETCH_WEATHER_STATION_DATASTREAM_LIST_REQUEST,
  fetchWeatherStationDatastreamListFailure,
  fetchWeatherStationDatastreamListSuccess,
} from "../store/actions/weatherStationDatastreamsList.actions";

const getWeatherStationDatastreamsList = async (token, thing) => {
  const url = `${API_GET_DATASTREAMS_LIST}?thing=${thing}`;
  const headers = {
    "content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleWeatherStationDatastreamsList(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const thing = action?.payload;
    const response = yield call(getWeatherStationDatastreamsList, token, thing);
    yield put(fetchWeatherStationDatastreamListSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWeatherStationDatastreamListFailure(error.message));
  }
}

export function* watchGetWeatherStationDatastreamList() {
  yield takeLatest(
    FETCH_WEATHER_STATION_DATASTREAM_LIST_REQUEST,
    handleWeatherStationDatastreamsList
  );
}
