import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { fetchFieldsDataRequest } from "../../store/actions/fields.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import { fetchActivityRequest } from "../../store/actions/activities.actions";
import { API_SOIL_PRAPARATIONS } from "../../config/api";
import {
  ADD_SOIL_PREPARATION,
  addSoilPreparationFailure,
  addSoilPreparationSuccess,
  DELETE_SOIL_PREPARATION,
  deleteSoilPreparationSuccess,
  deleteSoilPreparationFailure,
} from "../../store/actions/soilPreparations.actions";

const addSoilPreparation = async (token, activity) => {
  const fieldsUrl = `${API_SOIL_PRAPARATIONS}`;
  const data = activity;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleAddSoilPreparation(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activity = action?.payload;
    const response = yield call(addSoilPreparation, token, activity);
    yield put(addSoilPreparationSuccess(response));
    yield put(fetchActivityRequest(activity.activity));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(addSoilPreparationFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchAddSoilPreparation() {
  yield takeLatest(ADD_SOIL_PREPARATION, handleAddSoilPreparation);
}

const deleteSoilPreparation = async (token, id) => {
  const url = `${API_SOIL_PRAPARATIONS}/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(url, { headers });
  return response.data;
};

function* handleDeleteSoilPreparation(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activitySoilPreparation = action?.payload.id;
    const response = yield call(
      deleteSoilPreparation,
      token,
      activitySoilPreparation
    );
    yield put(deleteSoilPreparationSuccess(response));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchActivityRequest(action?.payload.activity));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(deleteSoilPreparationFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchDeleteSoilPreparation() {
  yield takeLatest(DELETE_SOIL_PREPARATION, handleDeleteSoilPreparation);
}
