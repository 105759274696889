import axios from "axios";
import { API_GET_COMMAND, API_SEND_COMMAND } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import {
  GET_COMMAND_STATUS_REQUEST,
  getCommandStatusFailure,
  getCommandStatusSuccess,
  SEND_COMMAND_REQUEST,
  sendCommandFailure,
  sendCommandSuccess,
} from "../../store/actions/irrigationCommand.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";

const sendCommandData = async (token, thing, device_eui, quantity, time) => {
  const url = `${API_SEND_COMMAND}?thing=${thing}`;
  const body = {
    info: {
      field_name: thing,
      device_eui: device_eui,
    },
    values: {
      action_type: "irrigate",
      irrigation_time: time,
      irrigation_water_count: quantity,
      irrigation_sequence: 1,
    },
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, body, { headers });
  return response.data;
};

function* handleSendCommandData(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const thing = action?.payload.thing;
    const device_eui = action?.payload.device_eui;
    const time = action?.payload.time;
    const quantity = action?.payload.quantity;
    const response = yield call(
      sendCommandData,
      token,
      thing,
      device_eui,
      quantity,
      time
    );
    yield put(sendCommandSuccess(response, action.payload));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error.response.data.error,
      })
    );
    yield put(hideProgress());
    yield put(sendCommandFailure(error.message));
  }
}

export function* watchSendCommandData() {
  yield takeLatest(SEND_COMMAND_REQUEST, handleSendCommandData);
}

const getCommandData = async (token, thing, device_eui) => {
  const url = `${API_GET_COMMAND}`;
  const body = {
    info: {
      field_name: thing,
      device_eui: device_eui,
    },
    values: {
      action_type: "device_status",
    },
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, body, { headers });
  return response.data;
};

function* handleGetCommandData(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const thing = action?.payload.thing;
    const device_eui = action?.payload.device_eui;
    const response = yield call(getCommandData, token, thing, device_eui);
    yield put(getCommandStatusSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(getCommandStatusFailure(error.message));
  }
}

export function* watchGetCommandData() {
  yield takeLatest(GET_COMMAND_STATUS_REQUEST, handleGetCommandData);
}
