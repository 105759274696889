import { useEffect, useState } from "react";
import chartMockData from "./mockChartData.json";

export const useTodayIntersection = () => {
  const [intersectionValues, setIntersectionValues] = useState({
    fullIrrigation: null,
    deficitIrrigation: null,
  });

  useEffect(() => {
    const todayLabel = "16 ott";
    const xIndex = chartMockData.labels.indexOf(todayLabel);

    if (xIndex !== -1) {
      const suggestedFullDataset = chartMockData.datasets.find(
        (d) => d.label === "Suggested full irrigation"
      );
      const suggestedDeficitDataset = chartMockData.datasets.find(
        (d) => d.label === "Suggested deficit irrigation"
      );
      setIntersectionValues({
        fullIrrigation: suggestedFullDataset
          ? suggestedFullDataset.data[xIndex]
          : null,
        deficitIrrigation: suggestedDeficitDataset
          ? suggestedDeficitDataset.data[xIndex]
          : null,
      });
    }
  }, []);
  return intersectionValues;
};

export const chartColors = {
  FULL_IRRIGATION: "rgba(44,108,140,255)",
  DEFICIT_IRRIGATION: "rgba(234,123,69,255)",
  SOIL_MOISTURE: "rgba(63,124,152,255)",
  TODAY: "rgba(144, 224, 239, 1)",
  SUGGESTED_DATE: "green",
  DELAYED_DATE: "rgba(239,157,118,255)",
  DEFICIT_DATE: "rgba(243,41,36,255)",
  LIGHT_FULL_IRRIGATION: "rgba(44,108,140,0.3)",
  LIGHT_DEFICIT_IRRIGATION: "rgba(234,123,69,0.3)",
  LIGHT_SOIL_MOISTURE: "rgba(63,124,152,0.3)",
  LIGHT_TODAY: "rgba(144, 224, 239, 0.3)",
  LIGHT_SUGGESTED_DATE: "rgba(0, 128, 0, 0.3)",
  LIGHT_DELAYED_DATE: "rgba(239,157,118,0.3)",
  LIGHT_DEFICIT_DATE: "rgba(243,41,36,0.3)",
};

export const data = {
  labels: chartMockData.labels,
  datasets: [
    {
      type: "line",
      label: chartMockData.datasets[0].label,
      borderColor: chartColors.FULL_IRRIGATION,
      borderWidth: 2,
      backgroundColor: chartColors.FULL_IRRIGATION,
      data: chartMockData.datasets[0].data,
      fill: false,
    },
    {
      type: "line",
      label: chartMockData.datasets[1].label,
      borderColor: chartColors.DEFICIT_IRRIGATION,
      borderWidth: 2,
      backgroundColor: chartColors.DEFICIT_IRRIGATION,
      data: chartMockData.datasets[1].data,
      fill: false,
    },
    {
      type: "line",
      label: chartMockData.datasets[2].label,
      backgroundColor: chartColors.SOIL_MOISTURE,
      borderColor: chartColors.SOIL_MOISTURE,
      borderDash: [5, 5],
      data: chartMockData.datasets[2].data,
      fill: false,
      yAxisID: "yRight",
    },
    {
      type: "line",
      label: "Today",
      backgroundColor: chartColors.TODAY,
      borderColor: chartColors.TODAY,
      fill: false,
    },
    {
      type: "line",
      label: "Suggested date",
      backgroundColor: "green",
      borderColor: "green",
      fill: false,
    },
    {
      type: "line",
      label: "Delated date",
      backgroundColor: chartColors.DELAYED_DATE,
      borderColor: chartColors.DELAYED_DATE,
      fill: false,
    },
    {
      type: "line",
      label: "Deficit date",
      backgroundColor: chartColors.DEFICIT_DATE,
      borderColor: chartColors.DEFICIT_DATE,
      fill: false,
    },
  ],
};

export const verticalLinePlugin = {
  id: "verticalLinePlugin",
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    const verticalLines = [
      {
        label: chartMockData.verticalLines[0].label,
        color: chartColors.TODAY,
        isToday: true,
      },
      {
        label: chartMockData.verticalLines[1].label,
        color: chartColors.SUGGESTED_DATE,
      },
      {
        label: chartMockData.verticalLines[2].label,
        color: chartColors.DELAYED_DATE,
      },
      {
        label: chartMockData.verticalLines[3].label,
        color: chartColors.DEFICIT_DATE,
      },
    ];

    ctx.save();

    const xPositionMap = {};

    verticalLines.forEach(({ label, color, isToday }) => {
      const xIndex = data.labels.indexOf(label);
      if (xIndex === -1) return;

      const x = xAxis.getPixelForValue(xIndex);

      if (!xPositionMap[x]) {
        xPositionMap[x] = 0;
      }
      const offset = xPositionMap[x] * 3;
      xPositionMap[x] += 1;

      const adjustedX = x + offset;

      ctx.beginPath();
      ctx.moveTo(adjustedX, yAxis.top);
      ctx.lineTo(adjustedX, yAxis.bottom);
      ctx.lineWidth = isToday ? 4 : 2;
      ctx.strokeStyle = color;
      ctx.stroke();
    });

    ctx.restore();
  },
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      labels: {
        boxWidth: 20,
      },
      position: "bottom",
    },
    title: {
      display: false,
      text: "Irrigation activities",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += context.raw + " m³";
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      position: "top",
    },
    y: {
      title: {
        display: true,
        text: "Quantity of water (m³)",
        font: {
          size: 14,
        },
      },
      min: 0,
    },
    yRight: {
      position: "right",
      title: {
        display: true,
        text: "Soil moisture (%)",
        font: {
          size: 14,
        },
      },
      min: 0,
      max: 100,
      ticks: {
        stepSize: 10,
      },
      grid: {
        display: false,
      },
    },
  },
};
