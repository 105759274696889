import axios from "axios";
import { API_GET_WEATHER_STATIONS } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import {
  FETCH_WEATHER_STATIONS_REQUEST,
  fetchWeatherStationsFailure,
  fetchWeatherStationsSuccess,
} from "../../store/actions/weatherStations.action";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";

const getWeatherStations = async (token) => {
  const url = `${API_GET_WEATHER_STATIONS}`;
  const headers = {
    "content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleWeatherStations() {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const response = yield call(getWeatherStations, token);
    yield put(fetchWeatherStationsSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWeatherStationsFailure(error.message));
  }
}

export function* watchGetWeatherStations() {
  yield takeLatest(FETCH_WEATHER_STATIONS_REQUEST, handleWeatherStations);
}
