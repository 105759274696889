import {
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "../actions/signup.actions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  alreadyRegistered: null,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        alreadyRegistered: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
        alreadyRegistered: action.alreadyRegistered,
      };
    default:
      return state;
  }
};

export default signupReducer;
