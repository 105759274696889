import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { sensorType } from "../../config/sensorTypeEnum";
import { updateSensorRequest } from "../../store/actions/sensors.actions";
import MapDrawMarkerComponent from "shared-components/src/components/map/mapDrawMarker.component";

const UpdateSensorComponent = ({
  sensor,
  open,
  onClose,
  onUpdate,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = {
    type: sensor?.type || "",
    eui: sensor?.eui || "",
    label: sensor?.label || "",
    id_thing: sensor?.id_thing || "",
    latitude: sensor?.position[0] || "",
    longitude: sensor?.position[1] || "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    eui: Yup.string().required("Required"),
    label: Yup.string().required("Required"),
    id_thing: Yup.string().required("Required"),
    latitude: Yup.number().typeError("Latitude value is not valid"),
    longitude: Yup.number().typeError("Longitude value is not valid"),
  });

  const handleSave = (values) => {
    const updatedSensor = {
      ...sensor,
      type: values.type,
      eui: values.eui,
      position: [values.latitude, values.longitude],
      label: values.label,
      id_thing: values.id_thing,
    };
    dispatch(updateSensorRequest(updatedSensor)).catch((error) => {
      console.error("Errore durante l'aggiornamento del sensore:", error);
    });
    onUpdate();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="update-sensor-dialog-title"
      aria-describedby="update-sensor-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "70%",
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {(formikProps) => (
          <Form>
            <DialogTitle id="alert-dialog-title">
              {t("setup-sensors-page.update-form-title")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="type-select-label">
                            {t("setup-sensors-page.add.type-sensor-label")}
                          </InputLabel>
                          <Select
                            label={t(
                              "setup-sensors-page.add.type-sensor-label"
                            )}
                            id={`type`}
                            name={`type`}
                            labelId="type-select-label"
                            value={formikProps.values.type}
                            onChange={(e) =>
                              formikProps.setFieldValue("type", e.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>{t("none")}</em>
                            </MenuItem>
                            {Object.values(sensorType).map((option, i) => (
                              <MenuItem key={i} value={option}>
                                {t(option)}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage name="type" component="div" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.name")}
                          name={`label`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`name`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.id_thing")}
                          name={`id_thing`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`name`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.device-eui")}
                          name={`eui`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`eui`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.latitude")}
                          name={`latitude`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`latitude`} component="div" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          as={TextField}
                          label={t("setup-sensors-page.add.longitude")}
                          name={`longitude`}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage name={`longitude`} component="div" />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <MapDrawMarkerComponent
                      markerPosition={{
                        lat: formikProps?.values?.latitude,
                        lng: formikProps?.values?.longitude,
                      }}
                      onMarkerPositionChange={(value) => {
                        formikProps.setFieldValue("latitude", value?.lat || "");
                        formikProps.setFieldValue(
                          "longitude",
                          value?.lng || ""
                        );
                      }}
                    ></MapDrawMarkerComponent>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t("setup-sensors-page.add.cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={onConfirm}
              >
                {t("setup-sensors-page.btn-update")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateSensorComponent;
