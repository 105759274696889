import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actionType, irrigationTypeEnum } from "../../config/reatimeValues";
import {
  calculateAmountFromTime,
  calculateTimeFromAmount,
  formatDate,
  getStatusInfo,
} from "../../utils/irrigationHandlers";
import {
  getCommandStatusRequest,
  sendCommandRequest,
} from "../../store/actions/irrigationCommand.actions";
import AlertInfoComponent from "shared-components/src/components/feedback/AlertInfo.component";
import { fetchActivityRequest } from "../../store/actions/activities.actions";
import dayjs from "dayjs";
import { addIrrigationRequest } from "../../store/actions/irrigations.actions";
import IrrigationAmountInput from "./IrrigationAmountInput.component";

const useIrrigationStatus = (
  field,
  status,
  dispatch,
  statusRequest,
  POLLING_INTERVAL_MS
) => {
  useEffect(() => {
    if (!field) return;
    const fetchStatus = () => dispatch(getCommandStatusRequest(statusRequest));

    if (status === "sent" || status === "processing" || status === undefined) {
      fetchStatus();
      const intervalId = setInterval(
        fetchStatus,
        status === "sent" ? 30000 : POLLING_INTERVAL_MS
      );
      return () => clearInterval(intervalId);
    }
  }, [dispatch, field, statusRequest, status, POLLING_INTERVAL_MS]);
};

const IrrigationActionsForm = ({ fullIrrigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const POLLING_INTERVAL_MS = 300000;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const platformAmount = fullIrrigation;

  const [irrigationType, setIrrigationType] = useState(
    irrigationTypeEnum.customAmount
  );
  const [irrigationValues, setIrrigationValues] = useState({
    amount: "",
    hours: "",
    minutes: "",
  });
  const [status, setStatus] = useState("");
  const [isIrrigationField, setIsIrrigationField] = useState(false);
  const [irrigationSensor, setIrrigationSensor] = useState("");
  const [inputError, setInputError] = useState("");
  const [processedIrrigation, setProcessedIrrigation] = useState({
    date: "",
    time: "",
    amount: "",
  });

  const minCustomValues = useMemo(
    () =>
      (irrigationValues.amount > 0 && irrigationValues.minutes >= 5) ||
      irrigationValues.hours > 0 ||
      irrigationType === "trusted_you",
    [irrigationValues, irrigationType]
  );
  const device_eui = irrigationSensor?.eui;

  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const sendResponse = useSelector(
    (state) => state?.irrigationCommandData?.data?.sendResponse
  );
  const commandData = useSelector(
    (state) => state?.irrigationCommandData?.data?.commandStatus?.details
  );
  const commandStatus = useSelector(
    (state) => state?.irrigationCommandData?.data?.commandStatus?.status
  );
  const last_irrigations = useSelector(
    (state) => state?.activities?.data?.attributes?.last_irrigations?.data
  );

  const activity = field?.activity?.id;
  const lastActivitiesIrrigation =
    last_irrigations?.slice().sort((a, b) => {
      const dateTimeA = new Date(
        `${a?.attributes?.date}T${a?.attributes?.time}Z`
      );
      const dateTimeB = new Date(
        `${b?.attributes?.date}T${b?.attributes?.time}Z`
      );
      return dateTimeB - dateTimeA;
    })[0] || null;

  const combineDate =
    lastActivitiesIrrigation?.attributes?.date &&
    lastActivitiesIrrigation?.attributes?.time
      ? new Date(
          `${lastActivitiesIrrigation?.attributes?.date}T${lastActivitiesIrrigation?.attributes?.time}Z`
        ).toISOString()
      : null;

  const activityAlreadyExist =
    processedIrrigation.date === lastActivitiesIrrigation?.attributes?.date &&
    processedIrrigation.time === lastActivitiesIrrigation?.attributes?.time &&
    processedIrrigation.amount === lastActivitiesIrrigation?.attributes?.amount;

  const lastIrrigationMessage = t(
    "irrigation_actions.last_irrigation_whit_send_quantity",
    {
      quantity: lastActivitiesIrrigation?.attributes?.amount || "-",
      date: formatDate(combineDate),
    }
  );

  const s_rate = field?.irrigations_system?.s_rate;
  const number = field?.irrigations_system?.number;
  const flowRate = s_rate * number;
  const timeFromPlatformAmount = calculateTimeFromAmount(
    platformAmount,
    flowRate
  );
  const formattedTimeFromPlatform =
    timeFromPlatformAmount.hours * 60 + timeFromPlatformAmount.minutes;

  const sendRequest = useMemo(
    () => ({
      thing: field.id_thing,
      device_eui: device_eui,
      actionType: actionType.irrigate,
      time:
        irrigationType === irrigationTypeEnum.trustedYou
          ? formattedTimeFromPlatform
          : irrigationValues.hours * 60 + irrigationValues.minutes,
      quantity:
        irrigationType === irrigationTypeEnum.customAmount
          ? Number(irrigationValues.amount)
          : platformAmount,
    }),
    [
      irrigationValues.amount,
      irrigationValues.hours,
      irrigationValues.minutes,
      device_eui,
      field.id_thing,
      formattedTimeFromPlatform,
      irrigationType,
      platformAmount,
    ]
  );

  const statusRequest = useMemo(
    () => ({
      thing: field.id_thing,
      device_eui: device_eui,
    }),
    [field.id_thing, device_eui]
  );

  useEffect(() => {
    const pumpSensor = field.sensors.find(
      (item) => item?.type === "pump_valve_controller"
    );
    const soilSensor = field.sensors.find(
      (item) => item?.type === "soil_moisture_sensor"
    );
    setIrrigationSensor(pumpSensor);
    setIsIrrigationField(
      !!pumpSensor && !!soilSensor && field?.irrigations_system?.system !== ""
    );
    setStatus(commandStatus);
  }, [field, commandStatus]);

  const handleIrrigationChange = (event) => {
    setIrrigationType(event.target.value);
  };

  const handleCustomAmountChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue === "" || /^\d*\.?\d+$/.test(inputValue)) {
      setIrrigationValues((prev) => ({ ...prev, amount: inputValue }));
      setInputError("");
      if (inputValue) {
        const { hours, minutes } = calculateTimeFromAmount(
          inputValue,
          flowRate
        );
        setIrrigationValues((prev) => ({
          ...prev,
          amount: inputValue,
          hours,
          minutes,
        }));
      } else {
        setIrrigationValues((prev) => ({
          ...prev,
          amount: inputValue,
          hours: 0,
          minutes: 0,
        }));
      }
    } else {
      setInputError("Invalid value");
    }
  };

  const handleCustomHoursChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setIrrigationValues((prev) => ({ ...prev, hours: inputValue }));

      const newAmount = calculateAmountFromTime(
        inputValue,
        irrigationValues.minutes,
        flowRate
      );
      setIrrigationValues((prev) => ({ ...prev, amount: newAmount }));
    }
  };

  const handleCustomMinutesChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue < 60) {
      setIrrigationValues((prev) => ({ ...prev, minutes: inputValue }));
      const newAmount = calculateAmountFromTime(
        irrigationValues.hours,
        inputValue,
        flowRate
      );
      setIrrigationValues((prev) => ({ ...prev, amount: newAmount }));
    }
  };

  const onSubmit = () => {
    dispatch(sendCommandRequest(sendRequest));
    setStatus("sent");
    setIrrigationValues({
      amount: "",
      hours: "",
      minutes: "",
    });
  };

  useEffect(() => {
    dispatch(fetchActivityRequest(activity));
  }, [dispatch, activity]);

  useIrrigationStatus(
    field,
    status,
    dispatch,
    statusRequest,
    POLLING_INTERVAL_MS
  );

  useEffect(() => {
    if (commandData) {
      setProcessedIrrigation({
        date: dayjs(commandData?.timestamp_irrigation_ended).format(
          "YYYY-MM-DD"
        ),
        time: dayjs(commandData?.timestamp_irrigation_ended).format("HH:mm:ss"),
        amount: commandData?.quantity,
      });
    }
  }, [commandData]);

  useEffect(() => {
    if (
      !activityAlreadyExist &&
      processedIrrigation?.date !== "" &&
      processedIrrigation?.time !== "" &&
      sendResponse?.result
    ) {
      let newActivity = {
        date: processedIrrigation.date,
        time: processedIrrigation.time,
        amount: commandData?.quantity,
        unit: commandData?.units,
      };
      newActivity = { ...newActivity, activity: field?.activity?.id };
      dispatch(addIrrigationRequest(newActivity));
    }
  }, [
    activityAlreadyExist,
    dispatch,
    commandData?.quantity,
    commandData?.units,
    field?.activity?.id,
    processedIrrigation.date,
    processedIrrigation.time,
    lastActivitiesIrrigation,
    sendResponse,
  ]);

  return (
    <>
      {isIrrigationField ? (
        <Box
          p={2}
          mt={2}
          display="flex"
          flexDirection="column"
          gap={1}
          backgroundColor="#f5f5f5"
        >
          <FormControl>
            <FormLabel
              id="radio-buttons-group-label"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {t("irrigation_actions.title")}
            </FormLabel>
            <Box
              p={1}
              borderRadius={1}
              backgroundColor="white"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={isMobile ? "column" : "row"}
              gap={1}
              mt={2}
              height="100%"
            >
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                value={irrigationType}
                onChange={handleIrrigationChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={irrigationTypeEnum.trustedYou}
                  control={<Radio />}
                  label={t("irrigation_actions.statusInfo.trustedYou")}
                />
                <Grid container alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      value={irrigationTypeEnum.customAmount}
                      control={<Radio />}
                      label={t("irrigation_actions.statusInfo.definedAmount")}
                    />
                  </Grid>
                  <Grid item>
                    {irrigationType === irrigationTypeEnum.customAmount && (
                      <IrrigationAmountInput
                        irrigationValues={irrigationValues}
                        handleCustomAmountChange={handleCustomAmountChange}
                        handleCustomHoursChange={handleCustomHoursChange}
                        handleCustomMinutesChange={handleCustomMinutesChange}
                        inputError={inputError}
                      />
                    )}
                  </Grid>
                </Grid>
              </RadioGroup>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                p={1}
                height="140px"
                width="500px"
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "fit-content",
                    height: "40px",
                    backgroundColor: "#156082",
                    textTransform: "none",
                  }}
                  onClick={onSubmit}
                  disabled={status !== "processed" || !minCustomValues}
                >
                  {t("irrigation_actions.buttonLabel")}
                </Button>
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={3}
                >
                  <Stack direction="column">
                    {getStatusInfo(status, t) === "processed" ? (
                      <Typography variant="body2">
                        {getStatusInfo(status, t).label}
                      </Typography>
                    ) : (
                      <Typography>{getStatusInfo(status, t).label}</Typography>
                    )}
                    {commandStatus === "processed" && (
                      <Typography color="gray" variant="body2">
                        {lastIrrigationMessage}
                      </Typography>
                    )}
                  </Stack>
                  <Box
                    borderRadius={100}
                    border="1.5px solid black"
                    bgcolor={getStatusInfo(status, t).color}
                    height={20}
                    width={20}
                  />
                </Stack>
              </Box>
            </Box>
          </FormControl>
        </Box>
      ) : undefined}
      <AlertInfoComponent message={inputError} />
    </>
  );
};

export default IrrigationActionsForm;
