import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import ListTableWithIdHideAndDelete from "shared-components/src/components/displayData/ListTableWithIdHideAndDelete.component";
import * as Yup from "yup";
import {
  addSoilPreparationRequest,
  deleteSoilPreparationRequest,
} from "../../store/actions/soilPreparations.actions";

const ActivitiesSoilPreparationComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const activities = useSelector((state) => state?.activities?.data);

  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);

  const soil_preparations =
    activities?.attributes?.soil_preparations?.data?.map(
      (soil_preparation) => ({
        id: soil_preparation?.id,
        type: soil_preparation?.attributes?.type,
        date: soil_preparation?.attributes?.date,
        "depth (cm)": soil_preparation?.attributes?.depth,
      })
    );

  const initialValues = {
    type: null,
    date: null,
    depth: null,
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    depth: Yup.string().required("Required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      let newActivity = {
        type: value.type,
        date: value.date,
        depth: value.depth,
      };

      newActivity = { ...newActivity, activity: field?.activity?.id };

      dispatch(addSoilPreparationRequest(newActivity));

      setOpen(false);
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteActivity(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  const deleteActivity = (value) => {
    if (value?.id) {
      dispatch(
        deleteSoilPreparationRequest({
          id: value?.id,
          activity: field?.activity?.id,
        })
      );
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListTableWithIdHideAndDelete
            rows={soil_preparations}
            tableTitle={`${t("activities_soil_preparation.title")}`}
            isRowDeletable={true}
            onRowDeleteClick={handleDeleteClick}
          ></ListTableWithIdHideAndDelete>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("add_new")}
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t("activities_soil_preparation.frm_add_soil.dialog_title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="select-label">Type</InputLabel>
                        <Select
                          labelId="select-label"
                          id="demo-simple-select"
                          // value={values.type}
                          label={t(
                            "activities_soil_preparation.frm_add_soil.type"
                          )}
                          onChange={(date) => {
                            console.log(date);
                            setFieldValue("type", date?.target?.value);
                          }}
                        >
                          <MenuItem value={"tillage"}>Tillage</MenuItem>
                          <MenuItem value={"plough"}>Plough</MenuItem>
                        </Select>
                      </FormControl>
                      <ErrorMessage name={`tillage`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t(
                            "activities_soil_preparation.frm_add_soil.date"
                          )}
                          name="date"
                          // value={values.date}
                          onChange={(date) => setFieldValue("date", date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name={`date`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t(
                          "activities_soil_preparation.frm_add_soil.depth"
                        )}
                        name={`depth`}
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name={`quantity`} component="div" />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("add_activity")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default ActivitiesSoilPreparationComponent;
