import { Box, TextField } from "@mui/material";

const IrrigationAmountInput = ({
  irrigationValues,
  handleCustomAmountChange,
  handleCustomHoursChange,
  handleCustomMinutesChange,
  inputError,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextField
        label="M^3"
        type="number"
        value={irrigationValues.amount}
        onChange={handleCustomAmountChange}
        error={!!inputError}
        helperText={inputError}
        sx={{ width: "100px" }}
      />
      <TextField
        label="Hours"
        type="number"
        value={irrigationValues.hours}
        onChange={handleCustomHoursChange}
        sx={{ width: "100px" }}
      />
      <TextField
        label="Minutes"
        type="number"
        value={irrigationValues.minutes}
        onChange={handleCustomMinutesChange}
        sx={{ width: "100px" }}
      />
    </Box>
  );
};

export default IrrigationAmountInput;
