import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_PESTICIDES } from "../../config/api";
import { fetchActivityRequest } from "../../store/actions/activities.actions";
import { fetchFieldsDataRequest } from "../../store/actions/fields.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import {
  ADD_PESTICIDE,
  addPesticideFailure,
  addPesticideSuccess,
  DELETE_PESTICIDE,
  deletePesticideFailure,
  deletePesticideSuccess,
} from "../../store/actions/pesticides.actions";

const addPesticide = async (token, activity) => {
  const fieldsUrl = `${API_PESTICIDES}`;
  const data = activity;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleAddPesticide(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activity = action?.payload;
    const response = yield call(addPesticide, token, activity);
    yield put(addPesticideSuccess(response));
    yield put(fetchActivityRequest(activity.activity));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(addPesticideFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchAddPesticide() {
  yield takeLatest(ADD_PESTICIDE, handleAddPesticide);
}

const deletePesticide = async (token, id) => {
  const url = `${API_PESTICIDES}/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(url, { headers });
  return response.data;
};

function* handleDeletePesticide(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    const activityPesticide = action?.payload.id;
    const response = yield call(deletePesticide, token, activityPesticide);
    yield put(deletePesticideSuccess(response));
    yield put(fetchFieldsDataRequest(farmId));
    yield put(fetchActivityRequest(action?.payload.activity));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message: error?.response?.data?.error?.message ?? error.message,
      })
    );
    yield put(deletePesticideFailure(error.message));
    yield put(hideProgress());
  }
}

export function* watchDeletePesticide() {
  yield takeLatest(DELETE_PESTICIDE, handleDeletePesticide);
}
