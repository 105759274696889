import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

export const defaultData = {
  labels: ["jul", "aug", "sep", "oct", "nov", "dec", "jan"],
  datasets: [
    {
      label: "Forecast min",
      data: [1024.34, 1023.64, 1045.12, 1038.04, 1167.08, 1133.75, 1120.17],
      fill: true,
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
    },
    {
      label: "Forecast max",
      data: [1024.34, 1025.13, 1073.27, 1066.87, 1292.78, 1245.46, 1188.3],
      fill: true,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
    },
    {
      label: "Forecast mean",
      data: [1024.34, 1024.15, 1050.78, 1049.72, 1234.93, 1201.49, 1154.93],
      fill: true,
      backgroundColor: "rgba(153, 102, 255, 0.2)",
      borderColor: "rgba(153, 102, 255, 1)",
    },
  ],
};

const HomeAreaChartComponent = ({
  data = defaultData,
  beginAtZero = false,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: beginAtZero,
      },
    },
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "400px",
  };

  return (
    <div style={containerStyle}>
      <Line data={data} options={options} />
    </div>
  );
};

export default HomeAreaChartComponent;
