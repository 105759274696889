export const FETCH_IRRIGATIONS_REQUEST = "FETCH_IRRIGATIONS_REQUEST";
export const FETCH_IRRIGATIONS_SUCCESS = "FETCH_IRRIGATIONS_SUCCESS";
export const FETCH_IRRIGATIONS_FAILURE = "FETCH_IRRIGATIONS_FAILURE";
export const ADD_IRRIGATION = "ADD_IRRIGATION";
export const ADD_IRRIGATION_SUCCESS = "ADD_IRRIGATION_SUCCESS";
export const ADD_IRRIGATION_FAILURE = "ADD_IRRIGATION_FAILURE";
export const UPDATE_IRRIGATION = "UPDATE_IRRIGATION";
export const UPDATE_IRRIGATION_SUCCESS = "UPDATE_IRRIGATION_SUCCESS";
export const UPDATE_IRRIGATION_FAILURE = "UPDATE_IRRIGATION_FAILURE";
export const DELETE_IRRIGATION = "DELETE_IRRIGATION";
export const DELETE_IRRIGATION_SUCCESS = "DELETE_IRRIGATION_SUCCESS";
export const DELETE_IRRIGATION_FAILURE = "DELETE_IRRIGATION_FAILURE";
export const DELETE_IRRIGATION_STATE = "DELETE_IRRIGATION_STATE";

export const addIrrigationRequest = (data) => {
  return {
    type: ADD_IRRIGATION,
    payload: data,
  };
};

export const addIrrigationSuccess = (data) => {
  return {
    type: ADD_IRRIGATION_SUCCESS,
    payload: data,
  };
};

export const addIrrigationFailure = () => {
  return {
    type: ADD_IRRIGATION_FAILURE,
  };
};

export const fetchIrrigationRequest = (farmId) => {
  return {
    type: FETCH_IRRIGATIONS_REQUEST,
    payload: farmId,
  };
};

export const fetchIrrigationSuccess = (data) => {
  return {
    type: FETCH_IRRIGATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchIrrigationFailure = () => {
  return {
    type: FETCH_IRRIGATIONS_FAILURE,
  };
};

export const deleteIrrigationRequest = (data) => {
  return {
    type: DELETE_IRRIGATION,
    payload: data,
  };
};

export const deleteIrrigationSuccess = (data) => {
  return {
    type: DELETE_IRRIGATION_SUCCESS,
    payload: data,
  };
};

export const deleteIrrigationFailure = () => {
  return {
    type: DELETE_IRRIGATION_FAILURE,
  };
};

export const deleteIrrigationState = () => {
  return {
    type: DELETE_IRRIGATION_STATE,
  };
};
