import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import * as React from "react";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
    paddingLeft: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTables = (props) => {
  const tableTitle = props.tableTitle ?? "";

  const rows = props.rows ?? [];

  return (
    <TableContainer component={Paper}>
      {tableTitle && (
        <div
          style={{
            padding: "10px",
            paddingLeft: "16px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {tableTitle}
        </div>
      )}
      <Table sx={{ minWidth: 100 }} aria-label="customized table">
        <TableBody>
          {rows &&
            rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.value}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomizedTables;

export const createData = (name, value) => {
  if (
    name === "date" ||
    name === "tillage" ||
    name === "plough" ||
    name === "planting_date" ||
    name === "harvest_date" ||
    name === "flowering_date" ||
    name === "rieping_date"
  ) {
    value = dayjs(value).format("DD/MM/YYYY");
  }

  if (name === "time") {
    value = value.substring(0, 5);
  }
  return { name, value };
};
