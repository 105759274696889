export const SEND_COMMAND_REQUEST = "SEND_COMMAND_REQUEST";
export const SEND_COMMAND_SUCCESS = "SEND_COMMAND_SUCCESS";
export const SEND_COMMAND_FAILURE = "SEND_COMMAND_FAILURE";

export const GET_COMMAND_STATUS_REQUEST = "GET_COMMAND_STATUS_REQUEST";
export const GET_COMMAND_STATUS_SUCCESS = "GET_COMMAND_STATUS_SUCCESS";
export const GET_COMMAND_STATUS_FAILURE = "GET_COMMAND_STATUS_FAILURE";

export const sendCommandRequest = (payload) => {
  return {
    type: SEND_COMMAND_REQUEST,
    payload,
  };
};
export const sendCommandSuccess = (data, payload) => {
  return {
    type: SEND_COMMAND_SUCCESS,
    payload: data,
  };
};
export const sendCommandFailure = () => {
  return {
    type: SEND_COMMAND_FAILURE,
  };
};

export const getCommandStatusRequest = (payload) => {
  return {
    type: GET_COMMAND_STATUS_REQUEST,
    payload: payload,
  };
};
export const getCommandStatusSuccess = (data) => {
  return {
    type: GET_COMMAND_STATUS_SUCCESS,
    payload: data,
  };
};
export const getCommandStatusFailure = () => {
  return {
    type: GET_COMMAND_STATUS_FAILURE,
  };
};
