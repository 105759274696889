import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Button, Grid, IconButton, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressComponent from "shared-components/src/components/feedback/CircularProgress.component";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

const ResetPasswordComponent = (props) => {
  const { logo, onResetPassword } = props;
  const { t } = useTranslation();
  const { error, success, loading } = useSelector(
    (state) => state?.resetPassword || {}
  );
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const handleResetPassword = (values) => {
    dispatch(onResetPassword({ ...values, code }));
  };

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const errorMessages = {
    required: t("signup_page.required"),
    passwordMismatch: t("signup_page.passwordMismatch"),
    passwordMin: t("signup_page.passwordMin"),
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, errorMessages.passwordMin)
      .required(errorMessages.required),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMismatch)
      .required(errorMessages.required),
  });

  return (
    <Fragment>
      <CircularProgressComponent />
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {logo && <img src={logo} style={{ maxWidth: "inherit" }} alt="" />}
          <h4>{t("reset_password_page.reset_password")}</h4>
          <h5>{t("reset_password_page.reset_password_subtitle")}</h5>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {t("reset_password_page.error")}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleResetPassword}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label={t("reset_password_page.password")}
                      name={"password"}
                      variant="outlined"
                      fullWidth
                      error={errors.password && touched.password}
                      helperText={errors.password}
                      disabled={success}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label={t("reset_password_page.confirm_password")}
                      name={"passwordConfirmation"}
                      variant="outlined"
                      fullWidth
                      error={
                        errors.passwordConfirmation &&
                        touched.passwordConfirmation
                      }
                      helperText={errors.passwordConfirmation}
                      disabled={success}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {success ? null : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                      >
                        {t("reset_password_page.reset")}
                      </Button>
                    )}
                    {success ? (
                      <Alert
                        severity="success"
                        style={{ marginBottom: "16px" }}
                      >
                        {t("reset_password_page.success")}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ResetPasswordComponent;
