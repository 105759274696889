import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { fieldInitialValues } from "../../config/fieldIntialValues";
import MapAddUpdateFieldComponent from "../map/MapAddUpdateField.component";
import ActivitiesFertilizerComponent from "./ActivitiesFertilizer.component";
import ActivitiesIrrigationComponent from "./ActivitiesIrrigation.component";
import ActivitiesPesticideComponent from "./ActivitiesPesticide.component";
import ActivitiesSoilPreparationComponent from "./ActivitiesSoilPreparation.component";
import CropFormComponent from "./CropForm.component";
import IrrigationFormComponent from "./IrrigationForm.component";
import SensorsFormComponent from "./SensorsForm.component";
import SoilTypeFormComponent from "./SoilTypeForm.component";
import { fetchWeatherStationsRequest } from "../../store/actions/weatherStations.action";

const FieldFormComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isWaterCounterPresent, setIsWaterCounterPresent] = useState(false);

  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const weatherStations = useSelector((state) => state?.weatherStations?.data);

  const [weatherStation, setWeatherStation] = useState(
    props.addNew ? "" : field?.weather_station?.id
  );

  const handleChange = (event) => {
    setWeatherStation(event.target.value);
  };

  useEffect(() => {
    if (field && field.sensors && Array.isArray(field.sensors)) {
      const hasWaterCounter = field.sensors.some(
        (sensor) => sensor.type === "water_counter"
      );
      setIsWaterCounterPresent(hasWaterCounter);
    }
  }, [field]);

  useEffect(() => {
    if (!weatherStations || weatherStations.length === 0) {
      dispatch(fetchWeatherStationsRequest());
    }
  }, [dispatch, weatherStations]);

  const initialValues = props.addNew ? fieldInitialValues : field;

  const validationSchema = Yup.object({
    label: Yup.string().required(t("field_form.required")),
    id_thing: Yup.string().required(t("field_form.required")),
  });

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              props.onSubmit({
                ...values,
                weather_station: weatherStation || null,
              });
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <div>
                      <h3>{t("field_form.title")}</h3>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{ height: "100%", padding: "20px" }}
                    >
                      <Grid container spacing={2} direction="column">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "700px",
                            gap: "20px",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={12} sm={12} md={4}>
                            <Field
                              as={TextField}
                              label={t("field_form.field_name")}
                              name="label"
                              variant="outlined"
                              error={formikProps.errors.label}
                              helperText={formikProps.errors.label}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <Field
                              as={TextField}
                              label={t("field_form.field_id_thing")}
                              name="id_thing"
                              variant="outlined"
                              error={formikProps.errors.id_thing}
                              helperText={formikProps.errors.id_thing}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <FormControl fullWidth>
                              <InputLabel id={"weather-station-select"}>
                                {t("field_form.field_weather_station")}
                              </InputLabel>
                              <Select
                                label={t("field_form.field_weather_station")}
                                id="weather_station"
                                name="weather_station"
                                value={weatherStation || ""}
                                onChange={handleChange}
                              >
                                <MenuItem value="">
                                  <em>{t("none")}</em>
                                </MenuItem>
                                {weatherStations &&
                                  weatherStations?.data?.map((station) => (
                                    <MenuItem
                                      key={station.id}
                                      value={station.id}
                                    >
                                      {station.attributes.label}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <ErrorMessage
                                name="weather_station"
                                component="div"
                              />
                            </FormControl>
                          </Grid>
                        </div>
                        <Grid item xs={12} sm={12} md={8}>
                          <MapAddUpdateFieldComponent
                            formikProps={formikProps}
                          ></MapAddUpdateFieldComponent>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  {!props.addNew && (
                    <Grid item xs={12}>
                      <div>
                        <h3>{t("activities")}</h3>
                      </div>
                      <Grid container spacing={2}>
                        {!isWaterCounterPresent && (
                          <Grid item xs={12} sm={12} md={6}>
                            <Paper
                              variant="outlined"
                              sx={{
                                height: "100%",
                                paddingX: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <ActivitiesIrrigationComponent
                                formikProps={formikProps}
                              ></ActivitiesIrrigationComponent>
                            </Paper>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={6}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: "100%",
                              paddingX: "20px",
                              paddingBottom: "20px",
                            }}
                          >
                            <ActivitiesSoilPreparationComponent
                              formikProps={formikProps}
                            ></ActivitiesSoilPreparationComponent>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: "100%",
                              paddingX: "20px",
                              paddingBottom: "20px",
                            }}
                          >
                            <ActivitiesFertilizerComponent
                              formikProps={formikProps}
                            ></ActivitiesFertilizerComponent>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: "100%",
                              paddingX: "20px",
                              paddingBottom: "20px",
                            }}
                          >
                            <ActivitiesPesticideComponent
                              formikProps={formikProps}
                            ></ActivitiesPesticideComponent>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{
                        height: "100%",
                        paddingX: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <IrrigationFormComponent
                        formikProps={formikProps}
                      ></IrrigationFormComponent>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{
                        height: "100%",
                        paddingX: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <SoilTypeFormComponent
                        formikProps={formikProps}
                      ></SoilTypeFormComponent>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{
                        height: "100%",
                        paddingX: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <CropFormComponent
                        formikProps={formikProps}
                      ></CropFormComponent>
                    </Paper>
                  </Grid>
                  {!props.addNew && (
                    <Grid item xs={12}>
                      <div>
                        <h3>{t("sensor")}</h3>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: "100%",
                              paddingX: "20px",
                              paddingBottom: "10px",
                            }}
                          >
                            <SensorsFormComponent
                              formikProps={formikProps}
                            ></SensorsFormComponent>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        position: "fixed",
                        bottom: "50px",
                        left: "55%",
                        transform: "translateX(-50%)",
                        zIndex: 1000,
                        boxShadow: 10,
                      }}
                    >
                      {props.addNew ? t("add_field") : t("update_field")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FieldFormComponent;
