import axios from "axios";
import { API_GET_DATASTREAMS_LIST } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";

import {
  FETCH_DATASTREAM_LIST_REQUEST,
  fetchDatastreamListFailure,
  fetchDatastreamListSuccess,
} from "../../store/actions/realtimeDatastreamList.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";

const getDatastreamList = async (token, thing) => {
  const url = `${API_GET_DATASTREAMS_LIST}?thing=${thing}`;
  const headers = {
    "content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleDatastreamList(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const thing = action?.payload;
    const response = yield call(getDatastreamList, token, thing);
    yield put(fetchDatastreamListSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchDatastreamListFailure(error.message));
  }
}

export function* watchGetDatastreamList() {
  yield takeLatest(FETCH_DATASTREAM_LIST_REQUEST, handleDatastreamList);
}
