import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import ListTableWithIdHideAndDelete from "shared-components/src/components/displayData/ListTableWithIdHideAndDelete.component";
import * as Yup from "yup";
import {
  addPesticideRequest,
  deletePesticideRequest,
} from "../../store/actions/pesticides.actions";

const ActivitiesPesticideComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const activities = useSelector((state) => state?.activities?.data);
  const pesticides = activities?.attributes?.pesticides?.data?.map(
    (pesticide) => ({
      id: pesticide?.id,
      type: pesticide?.attributes?.type,
      date: pesticide?.attributes?.date,
      "quantity (kg/ha)": pesticide?.attributes?.quantity,
    })
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);

  const initialValues = {
    type: "",
    date: "",
    quantity: "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    quantity: Yup.string().required("Required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      let newActivity = {
        type: value.type,
        date: value.date,
        quantity: value.quantity,
      };
      newActivity = { ...newActivity, activity: field?.activity?.id };

      dispatch(addPesticideRequest(newActivity));

      setOpen(false);
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteActivity(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  const deleteActivity = (value) => {
    if (value?.id) {
      dispatch(
        deletePesticideRequest({ id: value?.id, activity: field?.activity?.id })
      );
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListTableWithIdHideAndDelete
            rows={pesticides}
            tableTitle={`${t("activities_pesticide.title")}`}
            isRowDeletable={true}
            onRowDeleteClick={handleDeleteClick}
          ></ListTableWithIdHideAndDelete>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("add_new")}
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t("activities_pesticide.frm_add_pesticide.dialog_title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t("activities_pesticide.frm_add_pesticide.type")}
                        name={`type`}
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name={`type`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t(
                            "activities_pesticide.frm_add_pesticide.date"
                          )}
                          name="date"
                          // value={initialValues.date}
                          onChange={(date) => setFieldValue("date", date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name={`date`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t(
                          "activities_pesticide.frm_add_pesticide.quantity"
                        )}
                        name={`quantity`}
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name={`quantity`} component="div" />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("add_activity")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default ActivitiesPesticideComponent;
