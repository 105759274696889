import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import alertInfo from "shared-components/src/components/feedback/store/alertInfo.reducer";
import circularProgress from "shared-components/src/components/feedback/store/circularProgress.reducer";
import authReducer from "shared-components/src/components/login/store/auth.reducer";
import me from "shared-components/src/components/users/store/users.reducer";
import rootSaga from "../saga/sagas";
import farm from "./reducers/farm.reducer";
import field from "./reducers/field.reducer";
import fields from "./reducers/fields.reducer";
import oveview from "./reducers/oveview.reducer";
import planning from "./reducers/planning.reducer";
import weatherForecast from "shared-components/src/components/weatherForecast/store/reducer/weatherForecast.reducer";
import sensors from "./reducers/sensors.reducer";
import activities from "./reducers/activities.reducer";
import irrigations from "./reducers/irrigations.reducer";
import soil_preparation from "./reducers/soilPreparations.reducer";
import fertilizer from "./reducers/fertilizers.reducer";
import pesticides from "./reducers/pesticides.reducer";
import sensorsMeasure from "./reducers/realtime.reducer";
import sensorMeasureData from "./reducers/realtimeSensorMeasure.reducer";
import { LOGOUT } from "shared-components/src/components/login/store/auth.actions";
import signupReducer from "./reducers/signup.reducer";
import forgotPasswordReducer from "shared-components/src/components/forgotPassword/store/forgotPassword.reducer";
import resetPasswordReducer from "shared-components/src/components/resetPassword/store/resetPassword.reducer";
import datastreamList from "./reducers/realtimeDatastreamList.reducer";
import irrigationCommandData from "./reducers/irrigationCommand.reducer";
import weatherStations from "./reducers/weatherStations.reducer";
import weatherStationDatastreamList from "shared-components/src/components/weatherStation/store/reducers/weatherStationDatastreamsList.reducer";
import weatherStationDatastreamMeasureData from "shared-components/src/components/weatherStation/store/reducers/weatherStationTimeRangeMeasure.reducer";

const appReducer = combineReducers({
  auth: authReducer,
  user: me,
  circularProgress,
  alertInfo,
  farm,
  field,
  fields,
  weatherForecast,
  planning,
  oveview,
  sensors,
  activities,
  irrigations,
  soil_preparation,
  fertilizer,
  pesticides,
  sensorsMeasure,
  sensorMeasureData,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  datastreamList,
  irrigationCommandData,
  weatherStations,
  weatherStationDatastreamList,
  weatherStationDatastreamMeasureData,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
