export const FETCH_ACTIVITIES_REQUEST = "FETCH_ACTIVITIES_REQUEST";
export const FETCH_ACTIVITIES_SUCCESS = "FETCH_ACTIVITIES_SUCCESS";
export const FETCH_ACTIVITIES_FAILURE = "FETCH_ACTIVITIES_FAILURE";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILURE = "ADD_ACTIVITY_FAILURE";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";
export const DELETE_ACTIVITY_STATE = "DELETE_ACTIVITY_STATE";

export const addActivityRequest = (data) => {
  return {
    type: ADD_ACTIVITY,
    payload: data,
  };
};

export const addActivitySuccess = (data) => {
  return {
    type: ADD_ACTIVITY_SUCCESS,
    payload: data,
  };
};

export const addActivityFailure = () => {
  return {
    type: ADD_ACTIVITY_FAILURE,
  };
};

export const fetchActivityRequest = (farmId) => {
  return {
    type: FETCH_ACTIVITIES_REQUEST,
    payload: farmId,
  };
};

export const fetchActivitySuccess = (data) => {
  return {
    type: FETCH_ACTIVITIES_SUCCESS,
    payload: data,
  };
};

export const fetchActivityFailure = () => {
  return {
    type: FETCH_ACTIVITIES_FAILURE,
  };
};

export const deleteActivityRequest = (data) => {
  return {
    type: DELETE_ACTIVITY,
    payload: data,
  };
};

export const deleteActivitySuccess = (data) => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    payload: data,
  };
};

export const deleteActivityFailure = () => {
  return {
    type: DELETE_ACTIVITY_FAILURE,
  };
};

export const deleteActivityState = () => {
  return {
    type: DELETE_ACTIVITY_STATE,
  };
};
