import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import authService from "shared-components/src/components/login/auth.service";
import { checkTokenRequest } from "shared-components/src/components/login/store/auth.actions";
import MainLayout from "../layout/main.layout";
import FieldAddPage from "../pages/fieldAdd.page";
import FieldUpdatePage from "../pages/fieldUpdate.page";
import FieldsPage from "../pages/fields.page";
import HomePage from "../pages/home.page";
import LoginPage from "../pages/login.page";
import RealtimePage from "../pages/realtime.page";
import ShapefilePage from "../pages/shapefile.page";
import WikiPage from "../pages/wiki.page";
import { routerPaths } from "./routerPaths";
import SignupPage from "../pages/signup.page";
import ForgotPasswordPage from "../pages/forgotPassword.page";
import ResetPasswordPage from "../pages/resetPassword.page";

const Router = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = authService.isAuthenticated(token);
  const tokenRemember = authService.isRemember();

  useEffect(() => {
    if (tokenRemember) {
      dispatch(checkTokenRequest(tokenRemember));
    }
  }, []);

  return (
    <Routes>
      <Route
        index
        element={
          isAuthenticated ? (
            <Navigate to={routerPaths.home} />
          ) : (
            <Navigate to={routerPaths.login} />
          )
        }
      />
      <Route path={routerPaths.login} element={<LoginPage />} />
      <Route path={routerPaths.signup} element={<SignupPage />} />
      <Route
        path={routerPaths.forgot_password}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={routerPaths.reset_password}
        element={<ResetPasswordPage />}
      />
      {isAuthenticated ? (
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path={routerPaths.home} element={<HomePage />} />
          <Route path={routerPaths.fields} element={<FieldsPage />} />
          <Route path={routerPaths.realtime} element={<RealtimePage />} />
          <Route path={routerPaths.wiki} element={<WikiPage />} />
          <Route path={routerPaths.field_add} element={<FieldAddPage />} />
          <Route
            path={routerPaths.field_update}
            element={<FieldUpdatePage />}
          />
          <Route path={routerPaths.shapefile} element={<ShapefilePage />} />
        </Route>
      ) : (
        <Route path="/*" element={<Navigate to={""} />} />
      )}
    </Routes>
  );
};

export default Router;
