import { Alert, Button, Grid, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressComponent from "shared-components/src/components/feedback/CircularProgress.component";
import * as Yup from "yup";

const ForgotPasswordComponent = (props) => {
  const { logo, onForgotPassword } = props;
  const { t } = useTranslation();
  const { error, success, loading } = useSelector(
    (state) => state.forgotPassword || {}
  );
  const dispatch = useDispatch();

  const handleForgotPassword = (values) => {
    dispatch(onForgotPassword(values));
  };

  const errorMessages = {
    required: t("signup_page.required"),
    emailInvalid: t("signup_page.emailInvalid"),
  };

  const initialValues = { email: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(errorMessages.emailInvalid)
      .required(errorMessages.required),
  });

  return (
    <Fragment>
      <CircularProgressComponent />
      <Grid container spacing={2}>
        {/* Logo and title */}
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {logo && <img src={logo} style={{ maxWidth: "inherit" }} alt="" />}
          <h4>{t("forgot_password_page.forgot_password")}</h4>
          <h5>{t("forgot_password_page.forgot_password_subtitle")}</h5>
        </Grid>
        {/* Alert and form */}
        <Grid item xs={12}>
          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {t(`forgot_password_page.errors.${error}`)}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleForgotPassword}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("forgot_password_page.email")}
                      name={"email"}
                      variant="outlined"
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={errors.email}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {success ? null : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                      >
                        {t("forgot_password_page.send")}
                      </Button>
                    )}
                    {success ? (
                      <Alert
                        severity="success"
                        style={{ marginBottom: "16px" }}
                      >
                        {t("forgot_password_page.success")}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ForgotPasswordComponent;
