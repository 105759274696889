import { BarChartOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoDataPlaceholder = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid lightgray",
        marginTop: "20px",
        borderRadius: "10px",
        padding: "10px",
        height: "auto",
      }}
    >
      <BarChartOutlined style={{ color: "gray" }} />
      <Typography variant="h6" color="textSecondary">
        {message || t("real-time-page.no-data-available")}
      </Typography>
    </div>
  );
};

export default NoDataPlaceholder;
