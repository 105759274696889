import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import ListTableWithIdHideAndDelete from "shared-components/src/components/displayData/ListTableWithIdHideAndDelete.component";
import * as Yup from "yup";
import {
  addIrrigationRequest,
  deleteIrrigationRequest,
} from "../../store/actions/irrigations.actions";
import { irrigationHandlers } from "../../utils/irrigationHandlers";
import { sortIrrigationsByDateAndTime } from "../../utils/sortIrrigations";

const ActivitiesIrrigationComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [unitType, setUnitType] = useState("m3");
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const activities = useSelector((state) => state?.activities?.data);
  const irrigations = activities?.attributes?.last_irrigations?.data?.map(
    (irrigation) => ({
      id: irrigation?.id,
      "amount (m^3)": irrigation?.attributes?.amount,
      date: irrigation?.attributes?.date,
      time: irrigation?.attributes?.time,
    })
  );

  const initialValues = {
    date: "",
    time: "",
    amount: "",
    unit: "",
  };

  const validationSchema = Yup.object({
    date: Yup.string().required(
      t("activities_irrigations.frm_add_irrigation.required")
    ),
    time: Yup.string().nullable().optional(),
    amount: Yup.string().required(
      t("activities_irrigations.frm_add_irrigation.required")
    ),
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);
  const isSprinkler = field?.irrigations_system?.system === "sprinkler";
  const isDrip = field?.irrigations_system?.system === "drip";
  const isSubterranean = field?.irrigations_system?.system === "subterranean";
  const isSurfaceIrrigation =
    field?.irrigations_system?.system === "surface irrigation";
  const hasSystem = field?.irrigations_system?.system;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      let amountToSave = value.amount;

      if (unitType === "hours") {
        const handlers = irrigationHandlers(field);

        if (isSprinkler) {
          amountToSave = handlers.sprinkler(value);
        } else if (isDrip) {
          amountToSave = handlers.drip(value);
        } else if (isSubterranean) {
          amountToSave = handlers.subterranean(value);
        } else if (isSurfaceIrrigation) {
          amountToSave = handlers.surface_irrigation(value);
        }
      }
      let newActivity = {
        date: dayjs(value.date).format("YYYY-MM-DD"),
        time: value.time ? dayjs(value.time).format("HH:mm:ss.SSS") : null,
        amount: amountToSave,
        unit: unitType,
      };

      newActivity = { ...newActivity, activity: field?.activity?.id };

      dispatch(addIrrigationRequest(newActivity));

      setOpen(false);
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteActivity(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  const deleteActivity = (value) => {
    if (value?.id) {
      dispatch(
        deleteIrrigationRequest({
          id: value?.id,
          activity: field?.activity?.id,
        })
      );
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListTableWithIdHideAndDelete
            rows={sortIrrigationsByDateAndTime(irrigations)}
            tableTitle={`${t("activities_irrigations.title")}`}
            isRowDeletable={true}
            onRowDeleteClick={handleDeleteClick}
          ></ListTableWithIdHideAndDelete>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("add_new")}
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t("activities_irrigations.frm_add_irrigation.dialog_title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t(
                            "activities_irrigations.frm_add_irrigation.date"
                          )}
                          name="date"
                          format="DD/MM/YYYY"
                          // value={initialValues.date}
                          onChange={(date) => setFieldValue("date", date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name={`date`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label={t(
                            "activities_irrigations.frm_add_irrigation.time"
                          )}
                          name="time"
                          onChange={(time) => setFieldValue("time", time)}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name={`time`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t(
                          "activities_irrigations.frm_add_irrigation.amount"
                        )}
                        name={`amount`}
                        variant="outlined"
                        style={{ width: "230px" }}
                      />
                      <ErrorMessage name={`amount`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div>
                        {t(
                          "activities_irrigations.frm_add_irrigation.unit_type"
                        )}
                      </div>
                      <RadioGroup
                        row
                        value={unitType}
                        onChange={(event) => setUnitType(event.target.value)}
                      >
                        <FormControlLabel
                          value="m3"
                          control={<Radio />}
                          label={t(
                            "activities_irrigations.frm_add_irrigation.unit_m3"
                          )}
                        />
                        <FormControlLabel
                          value="hours"
                          control={<Radio />}
                          label={t(
                            "activities_irrigations.frm_add_irrigation.unit_hours"
                          )}
                          disabled={!hasSystem}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("add_activity")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default ActivitiesIrrigationComponent;
