import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_SIGNUP } from "../../config/api";
import {
  SIGNUP_REQUEST,
  signupFailure,
  signupSuccess,
} from "../../store/actions/signup.actions";

const signup = async (data) => {
  const url = `${API_SIGNUP}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleSignup(action) {
  try {
    yield put(showProgress());
    const response = yield call(signup, action?.payload);
    yield put(signupSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    let alreadyRegistered = null;
    if (error.response?.status === 400) {
      const { details } = error.response.data.error;
      if (details?.active_platform) {
        alreadyRegistered = details;
      }
    }
    yield put(
      showAlertInfo({
        type: alertInfoTypes.ERROR,
        message:
          error?.response?.data?.details ??
          error?.response?.data?.error?.message ??
          error.message,
      })
    );
    if (alreadyRegistered) {
      yield put(
        signupFailure(
          error?.response?.data?.details ??
            error?.response?.data?.error?.message ??
            error.message,
          alreadyRegistered
        )
      );
    } else {
      yield put(
        signupFailure(
          error?.response?.data?.details ??
            error?.response?.data?.error?.message ??
            error.message,
          null
        )
      );
    }
    yield put(hideProgress());
  }
}

export function* watchSignup() {
  yield takeLatest(SIGNUP_REQUEST, handleSignup);
}
