import { Box, Stack, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useTranslation } from "react-i18next";

const WeatherWidget = ({
  iconCurrent,
  weather,
  day,
  temperature,
  minTemp,
  maxTemp,
  humidity,
  rain,
  wind,
  windDirection,
  location,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      height={200}
      width="auto"
      borderRadius={4}
      p={2}
      sx={{
        background: "linear-gradient(to bottom, #0082C7, #00AEDC)",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2" color="white" fontWeight="bold">
            {day}
          </Typography>
          {location && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <LocationOnOutlinedIcon
                sx={{ color: "white" }}
                fontSize="small"
              />
              <Typography variant="body2" color="white">
                {location}
              </Typography>
            </div>
          )}
        </div>
        {iconCurrent && (
          <img src={iconCurrent} alt={weather} height={40} width={40} />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {temperature ? (
          <Typography fontWeight="bold" variant="h5" color="white">
            {temperature}°
          </Typography>
        ) : (
          <Typography fontWeight="bold" variant="h5" color="white"></Typography>
        )}

        <Stack spacing={0.5} alignItems="end">
          <Typography variant="caption" color="white">
            Min {minTemp}°
          </Typography>
          <Typography variant="caption" color="white">
            Max {maxTemp}°
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="white">
          {t("weather-forecast.humidity")} %
        </Typography>
        <Typography color="white">{humidity}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="white">{t("weather-forecast.rain")}</Typography>
        <Typography color="white">{rain}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="white">{t("weather-forecast.wind")}</Typography>
        <Typography color="white">
          {wind} {windDirection}
        </Typography>
      </Stack>
    </Box>
  );
};

export default WeatherWidget;
