import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeatherStationDatastreamListRequest } from "./store/actions/weatherStationDatastreamsList.actions";
import { fetchWeatherStationDatastreamMeasureDataRequest } from "./store/actions/weatherStationTimeRangeMeasure.actions";
import LineChartComponent from "../charts/lineChart.component";
import NoDataPlaceholder from "../noDataPlaceholder/noDataPlaceholder.component";

const WeatherStationComponent = ({
  weatherStation,
  getLabels,
  getDatasets,
  start_time,
  end_time,
  field,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id_thing_system = weatherStation?.id_thing_system;
  const [selectedMeasure, setSelectedMeasure] = useState("");
  const datastreamsList = useSelector(
    (state) => state?.weatherStationDatastreamList?.data
  );
  const datastreamMeasureData = useSelector(
    (state) => state?.weatherStationDatastreamMeasureData?.data
  );

  const datastreamChartData = Array.isArray(datastreamMeasureData)
    ? {
        labels: getLabels(datastreamMeasureData),
        datasets: getDatasets(datastreamMeasureData),
      }
    : null;

  const unit = datastreamMeasureData?.[0]?.unit_of_measurement || "Unità";

  const handleMeasureChange = (event) => {
    const datastream_name = event.target.value;
    setSelectedMeasure(datastream_name);
  };

  useEffect(() => {
    if (weatherStation) {
      dispatch(fetchWeatherStationDatastreamListRequest(id_thing_system));
    }
    if (weatherStation || field) {
      setSelectedMeasure("");
    }
  }, [id_thing_system, dispatch, weatherStation, field]);

  const fetchMeasureData = useCallback(() => {
    if (selectedMeasure) {
      dispatch(
        fetchWeatherStationDatastreamMeasureDataRequest({
          field_name: id_thing_system,
          name: selectedMeasure,
          start_time: start_time.toISOString(),
          end_time: end_time.toISOString(),
        })
      );
    }
  }, [selectedMeasure, dispatch, id_thing_system, end_time, start_time]);

  useEffect(() => {
    fetchMeasureData();
  }, [selectedMeasure, fetchMeasureData]);

  useEffect(() => {
    fetchMeasureData();
  }, [start_time, end_time, fetchMeasureData]);

  return (
    <Paper
      variant="outlined"
      sx={{ height: "100%", padding: "20px", width: "100%" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, mt: 2 }}>
          {t("weather_station_component.title")}
          {weatherStation ? ":" : ""}
        </Typography>
        <Typography variant="h6">{weatherStation?.label}</Typography>
      </Box>
      <FormControl sx={{ width: "200px" }}>
        <InputLabel id="weather-station-measure-select-label">
          {t("weather_station_component.input_label_measure")}
        </InputLabel>
        <Select
          labelId="weather-station-measure-select-label"
          id="weather-station-measure-select"
          label={t("weather_station_component.input_label_measure")}
          value={selectedMeasure}
          onChange={handleMeasureChange}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "200px",
                overflowY: "auto",
              },
            },
          }}
          disabled={!weatherStation}
        >
          {Array.isArray(datastreamsList) && datastreamsList.length > 0 ? (
            datastreamsList.map((option, i) => (
              <MenuItem key={i} value={option.name}>
                {option.observed_property}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              {t("weather_station_component.empty_select")}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {selectedMeasure && (
        <Box sx={{ height: "100%" }}>
          {datastreamChartData?.datasets?.[0]?.data.length > 0 ? (
            <>
              <h4>{t("weather_station_component.chart_datastream_title")}</h4>
              <LineChartComponent data={datastreamChartData} unit={unit} />
            </>
          ) : (
            <NoDataPlaceholder />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default WeatherStationComponent;
