import axios from "axios";
import { API_SENSOR_MEASURE_DATA } from "../../../../../acquaount-dashboard-farm/src/config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "../../feedback/store/circularProgress.actions";
import {
  FETCH_WEATHER_STATION_DATASTREAM_MEASURE_REQUEST,
  fetchWeatherStationDatastreamMeasureDataFailure,
  fetchWeatherStationDatastreamMeasureDataSuccess,
} from "../store/actions/weatherStationTimeRangeMeasure.actions";
import { showAlertInfo } from "../../feedback/store/alertInfo.actions";
import { alertInfoTypes } from "../../feedback/alertInfoTypes.enum";

const getWeatherStationDatastreamMeasureData = async (
  token,
  id_thing_system,
  name,
  start_time,
  end_time
) => {
  const url = `${API_SENSOR_MEASURE_DATA}?field_name=${id_thing_system}&name=${name}&start_time=${start_time}&end_time=${end_time}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleWeatherStationDatastreamMeasureData(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const id_thing_system = action?.payload.field_name;
    const name = action?.payload.name;
    const start_time = action?.payload.start_time;
    const end_time = action?.payload.end_time;

    const response = yield call(
      getWeatherStationDatastreamMeasureData,
      token,
      id_thing_system,
      name,
      start_time,
      end_time
    );
    yield put(fetchWeatherStationDatastreamMeasureDataSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchWeatherStationDatastreamMeasureDataFailure(error.message));
  }
}

export function* watchGetWeatherStationDatastreamMeasureData() {
  yield takeLatest(
    FETCH_WEATHER_STATION_DATASTREAM_MEASURE_REQUEST,
    handleWeatherStationDatastreamMeasureData
  );
}
