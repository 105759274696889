const host = process.env.REACT_APP_BACKEND_URL || "http://localhost:1337";
// const host = "https:///backend.acquaount.development.abidevops.website";

export const weatherApiKey = "c45d67942b6f42a4ac3123410240807";
export const customTokenKey = process.env.REACT_APP_CUSTOM_TOKEN_KEY || "AAA";

const api_root = "/api";
export const API_URL_LOGIN = `${host}${api_root}/auth/local`;
export const API_GET_ME = `${host}${api_root}/users/me`;
export const API_GET_FARM_DATA = `${host}${api_root}/farms`;
export const API_GET_FIELDS_DATA = `${host}${api_root}/fieldsFromFarm`;
export const API_ADD_FIELD_DATA = `${host}${api_root}/fields`;
export const API_UPDATE_FIELD_DATA = `${host}${api_root}/fields`;
export const API_POPULATE_ALL = `?populate=*`;
export const API_SENSORS = `${host}${api_root}/sensors`;
export const API_ACTIVITIES = `${host}${api_root}/activities`;
export const API_IRRIGATIONS = `${host}${api_root}/last-irrigations`;
export const API_SOIL_PRAPARATIONS = `${host}${api_root}/soil-preparations`;
export const API_PESTICIDES = `${host}${api_root}/pesticides`;
export const API_FERTILIZERS = `${host}${api_root}/fertilizers`;
export const API_ALL_SENSORS_MEASURE = `${host}${api_root}/get-all-sensors-measure`;
export const API_SENSOR_MEASURE_DATA = `${host}${api_root}/get-measures-by-time-range`;
export const API_COMMAND_SENSOR = `${host}${api_root}/sensor-actions`;
export const API_WEATHER_DATA = `${host}${api_root}/get-weather-data`;
export const API_SIGNUP = `${host}${api_root}/auth/local/register`;
export const API_FORGOT_PASSWORD = `${host}${api_root}/auth/forgot-password`;
export const API_RESET_PASSWORD = `${host}${api_root}/auth/reset-password`;
export const API_GET_DATASTREAMS_LIST = `${host}${api_root}/get-datastreams-list`;
export const API_SEND_COMMAND = `${host}${api_root}/send-command`;
export const API_GET_COMMAND = `${host}${api_root}/get-command`;
export const API_GET_WEATHER_STATIONS = `${host}${api_root}/weather-stations`;
