import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { climateZoneOptions, soilType } from "../../config/fieldIntialValues";
import CustomTooltip from "shared-components/src/components/tooltip/customTooltip.component";

const SoilTypeFormComponent = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = props?.formikProps;
  const [climateZone, setClimateZone] = React.useState(
    values?.soil_type?.climate_zone
  );
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== climateZone) {
      setClimateZone(newValue);
      setFieldValue("soil_type.climate_zone", newValue);
    }
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4 style={{ marginRight: "8px" }}>{t("soilType.title")}</h4>
        <CustomTooltip title={t("soilType.soil_porosity_info")} />
      </div>

      <Grid container spacing={2}>
        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">
                {t("soilType.climate_zone")}
              </InputLabel>
              <Select
                label={t("crop_form.select_label_type")}
                id="soil_type.climate_zone"
                name="soil_type.climate_zone"
                labelId="type-select-label"
                value={values?.soil_type?.climate_zone}
                onChange={handleSelectChange}
              >
                <MenuItem value="">
                  <em>{t("none")}</em>
                </MenuItem>
                {Object.values(climateZoneOptions).map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage name="crop.type" component="div" />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.clay"
              )} ${t("soilType.unit.clay")}`}
              name="soil_type.clay"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.clay" component="div" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.silt"
              )} ${t("soilType.unit.silt")}`}
              name="soil_type.silt"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.silt" component="div" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.sand"
              )} ${t("soilType.unit.sand")}`}
              name="soil_type.sand"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.sand" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.field_capacity)} ${t(
                "soilType.unit." + soilType.field_capacity
              )}`}
              name="soil_type.field_capacity"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.field_capacity" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.permanent_wilting_point)} ${t(
                "soilType.unit." + soilType.permanent_wilting_point
              )}`}
              name="soil_type.permanent_wilting_point"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage
              name="soil_type.permanent_wilting_point"
              component="div"
            />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t("soilType." + soilType.bulk_density)} ${t(
                "soilType.unit." + soilType.bulk_density
              )}`}
              name="soil_type.bulk_density"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.bulk_density" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t("soilType." + soilType.soil_porosity)} ${t(
                "soilType.unit." + soilType.soil_porosity
              )}`}
              name="soil_type.soil_porosity"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.soil_porosity" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t("soilType." + soilType.water_content_saturation)} ${t(
                "soilType.unit." + soilType.water_content_saturation
              )}`}
              name="soil_type.water_content_saturation"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage
              name="soil_type.water_content_saturation"
              component="div"
            />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t(
                "soilType." + soilType.water_content_saturation_by_sensor
              )} ${t(
                "soilType.unit." + soilType.water_content_saturation_by_sensor
              )}`}
              name="soil_type.water_content_saturation_by_sensor"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage
              name="soil_type.water_content_saturation_by_sensor"
              component="div"
            />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t(
                "soilType." + soilType.saturated_hydraulic_conductivity
              )} ${t(
                "soilType.unit." + soilType.saturated_hydraulic_conductivity
              )}`}
              name="soil_type.saturated_hydraulic_conductivity"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage
              name="soil_type.saturated_hydraulic_conductivity"
              component="div"
            />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t("soilType." + soilType.organic_matter)} ${t(
                "soilType.unit." + soilType.organic_matter
              )}`}
              name="soil_type.organic_matter"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.organic_matter" component="div" />
          </Grid>
        </Fragment>
      </Grid>
    </Fragment>
  );
};

export default SoilTypeFormComponent;
